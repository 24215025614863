import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  BackIcon,
  openRightSideBar,
  SideBarIcon,
} from "../../../components/TopHeader/TopHeader";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ShowToast } from "../../../helpers/Helpers";
import { API_VIEW_COURSE } from "../../../config/Endpoints";
import CreateCourse from "./CreateCourse";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
};

const week = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];

export const CourseUsersWeekData = {
  labels: week,
  datasets: [
    {
      label: "Users This Week",
      backgroundColor: "#eb7c45",
      borderColor: "#eb7c45",
      data: [22, 24, 32, 30, 36, 30, 34],
    },
  ],
};

export function CourseUsersWeekly() {
  return <Line options={options} data={CourseUsersWeekData} />;
}

const CourseDetailPage = (props) => {
  let { id } = useParams();
  const [courseDetails, setCourseDetails] = useState({
    courseRecentDate: { dateTimeStart: "", dateTimeEnd: "" },
  });
  const [courseForm, setCourseDetail] = useState(
    "right-side-bar g-0 overflow-auto"
  );

  const fetchCourseDetail = () => {
    fetch(API_VIEW_COURSE + "?id=" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          if (errorList) {
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
          } else {
            ShowToast("error", "Something went wrong!");
          }
        } else {
          setCourseDetails(data.data);
        }
      });
  };

  const handleReloadData = () => {
    console.log("From Comp");
    fetchCourseDetail();
  };

  useEffect(() => {
    fetchCourseDetail();
  }, []);

  return (
    <>
      <div className="wrapper d-flex">
        <SidebarMenu />
        <div className="wrapper-body">
          <div className="top-header pe-4">
            <SideBarIcon />
            <BackIcon path={"/training"} />
            <h3>Course Detail</h3>
          </div>
          <div className="events">
            <div className="container-fluid p-4">
              <div className="row">
                <div className="col-12">
                  <h2 className="events__title">{courseDetails.name}</h2>
                  <p className="theme-text-blue-grey">
                    {courseDetails.headline}
                    <Link
                      className="ms-auto p-2 theme-text small"
                      onClick={openRightSideBar}
                    >
                      <img src="/images/icons/edit_theme.svg" alt="Edit" />
                      &nbsp;&nbsp;Edit Range
                    </Link>
                  </p>

                  <hr />
                </div>
                <div className="col-md-8 events__border-right">
                  <h3 className="fs-6 theme-text-blue-grey d-flex">
                    Course Images
                  </h3>
                  <div className="events__gallery">
                    <img
                      src="/images/big-durobeen-gun.png"
                      alt="Home Firearm Safety Course"
                    />
                    <img
                      src="/images/big-man.png"
                      alt="Home Firearm Safety Course"
                    />
                    <img
                      src="/images/courses-big-black-gun.png"
                      alt="Home Firearm Safety Course"
                    />
                  </div>
                  <hr />
                  <h3 className="fs-6 theme-text-blue-grey d-flex">
                    Course Detail
                  </h3>
                  {courseDetails.description}
                </div>
                <div className="col-md-4">
                  <h3 className="fs-6 theme-text-blue-grey d-flex">
                    Key Highlights
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td>Instructor</td>
                        <td>Kenneth Gains</td>
                      </tr>
                      <tr>
                        <td>Start Date</td>
                        <td>
                          {courseDetails.courseRecentDate
                            ? courseDetails.courseRecentDate.dateTimeStart
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>End Date</td>
                        <td>
                          {courseDetails.courseRecentDate
                            ? courseDetails.courseRecentDate.dateTimeEnd
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Duration</td>
                        <td>8 Hours</td>
                      </tr>
                    </tbody>
                  </table>

                  <h3 className="fs-6 theme-text-blue-grey d-flex mt-5">
                    Analytics
                  </h3>
                  <table> 
                    <tbody className="disabled">
                      <tr>
                        <td>Users Subscribed</td>
                        <td>254</td>
                      </tr>
                      <tr>
                        <td>Average Play Time</td>
                        <td>152 Hours</td>
                      </tr>
                    </tbody>
                  </table>

                  {/*                   
                  <div className="mt-4" style={{ height: "200px" }}>
                    <CourseUsersWeekly />
                  </div>
                   */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateCourse
        reloadData={handleReloadData}
        updateData={courseDetails}
        courseSideBarClass={courseForm}
      />
    </>
  );
};

export default CourseDetailPage;
