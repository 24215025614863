import React from 'react';
import { AddIcon, SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const Notifications = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <AddIcon />
                        <h3>Notifications Management</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th className="text-center">Uploaded On</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Hello there! It is time to update
                                            </td>
                                            <td>This is description for notification and it is should be done by today itself.</td>
                                            <td align='center'>Sep 02, 2022</td>
                                            <td className="theme-text text-center">Edit</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Hello there! It is time to update
                                            </td>
                                            <td>This is description for notification and it is should be done by today itself.</td>
                                            <td align='center'>Sep 02, 2022</td>
                                            <td className="theme-text text-center">Edit</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Hello there! It is time to update
                                            </td>
                                            <td>This is description for notification and it is should be done by today itself.</td>
                                            <td align='center'>Sep 02, 2022</td>
                                            <td className="theme-text text-center">Edit</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Notifications;