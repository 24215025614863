import React from 'react';
import { Link } from 'react-router-dom';
import { SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const Timesheet = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <h3>Timesheet</h3>
                        <ul>
                            <li><Link to={'/employees'}>Employees</Link></li>
                            <li><Link to={'/tasks'}>Tasks</Link></li>
                            <li><Link to={'/timesheet'}><span className="theme-text">Timesheet</span></Link></li>
                        </ul>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Timesheet;