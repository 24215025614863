import React from 'react';
import { SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const Discounts = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <h3>Discounts Management</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="discounts">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="item">
                                                <img src="./images/new-gun-1.png" alt="" className="img-fluid" />
                                                <div className="detail">
                                                    <h3 className="pb-2">Firearms History Discount</h3>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p>HFH383XGS</p>
                                                        <p>50%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <img src="./images/new-gun-1.png" alt="" className="img-fluid" />
                                                <div className="detail">
                                                    <h3 className="pb-2">Firearms History Discount</h3>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p>HFH383XGS</p>
                                                        <p>50%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <img src="./images/new-gun-1.png" alt="" className="img-fluid" />
                                                <div className="detail">
                                                    <h3 className="pb-2">Firearms History Discount</h3>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p>HFH383XGS</p>
                                                        <p>50%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <img src="./images/new-gun-1.png" alt="" className="img-fluid" />
                                                <div className="detail">
                                                    <h3 className="pb-2">Firearms History Discount</h3>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p>HFH383XGS</p>
                                                        <p>50%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><div className="col-md-4">
                                            <div className="item">
                                                <img src="./images/new-gun-1.png" alt="" className="img-fluid" />
                                                <div className="detail">
                                                    <h3 className="pb-2">Firearms History Discount</h3>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p>HFH383XGS</p>
                                                        <p>50%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Discounts;