import React, { useState, useEffect } from "react";
import {
  BackCloseRightBarIcon,
  closeRightSideBar,
} from "../../../components/TopHeader/TopHeader";
import {
  API_CREATE_COURSE,
  API_EDIT_RANGE,
  API_CREATE_RANGE,
} from "../../../config/Endpoints";
import {
  ShowToast,
  getCountry,
  getCity,
  getState,
} from "../../../helpers/Helpers";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import moment from "moment";

const CreateRange = (props) => {
  const rangeSideBarClass = props.rangeSideBarClass;
  const reloadData = props.reloadData;
  const updateData = props.updateData;
  const [isBtnLoading, setBtnIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [location, setLocation] = useState(null);
  const [operationHours, setOperationHours] = useState([]);
  const hours = {};
  hours[1] = { hour_open: "", hour_close: "" };
  hours[2] = { hour_open: "", hour_close: "" };
  hours[3] = { hour_open: "", hour_close: "" };
  hours[4] = { hour_open: "", hour_close: "" };
  hours[5] = { hour_open: "", hour_close: "" };
  hours[6] = { hour_open: "", hour_close: "" };
  hours[7] = { hour_open: "", hour_close: "" };
  const [rangeDetail, setRangeDetail] = useState({
    name: "",
    hours_of_operation: hours,
    hour_close: Math.floor(new Date().getTime() / 1000),
  });

  const rangeForm = (evt) => {
    const value = evt.target.value;
    console.log(evt.target.name, value);
    setRangeDetail({
      ...rangeDetail,
      [evt.target.name]: value,
    });
    console.log(rangeDetail);
  };

  const handleChangeTime = (evt, day) => {
    const value = evt.target.value;
    console.log("day", day);
    console.log(evt.target.name, value);

    const date = moment().format("YYYY-MM-DD");
    console.log(date);

    // const date = new Date().getDate();
    const dateTime = new Date(date + " " + value);
    const UTC = Math.floor(dateTime.getTime() / 1000);
    console.log(UTC);
    // setRangeDetail({
    //   ...rangeDetail,
    //   [evt.target.name]: UTC,
    // });

    //
    let newArr = [...operationHours];
    newArr[day] = UTC;
    setOperationHours(newArr);
    console.log(rangeDetail);
  };

  const handleDateChange = (evt) => {
    const value = evt.target.value;
    const date = new Date(value);
    const UTC = Math.floor(date.getTime() / 1000);
    console.log(evt.target.name, UTC);
    setRangeDetail({
      ...rangeDetail,
      [evt.target.name]: UTC,
    });
    console.log(rangeDetail);
  };

  const handleLocationChange = (value) => {
    console.log(value);
    console.log(value.value.description);

    const placeID = value.value.place_id;
    geocodeByPlaceId(placeID)
      .then((results) => {
        console.log(results);
        console.log(getCountry(results[0].address_components));
        console.log(getCity(results[0].address_components));
        console.log(getState(results[0].address_components));
        setRangeDetail({
          ...rangeDetail,
          street_address: value.value.description,
          city: getCity(results[0].address_components),
          state: getState(results[0].address_components),
          country: getCountry(results[0].address_components),
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          zip: "1",
        });
      })
      .catch((error) => console.error(error));
    setLocation(value);
  };

  // const convert = (array, count = { n: 1 }) =>
  //   Object.fromEntries(
  //     array.map((v) =>
  //       Array.isArray(v) ? ["array" + count.n++, convert(v, count)] : [v, v]
  //     )
  //   );
  // data = [1, 2, [4, 5, 6], 7, 8, [9, 0], 11, 12],
  // result = convert(data);

  // console.log(result);

  useEffect(() => {
    if (
      typeof updateData !== "undefined" &&
      typeof updateData.id !== "undefined"
    ) {
      // setRangeDetail(updateData);
      const sunday_open = moment(
        updateData.operationHours[0].hourOpen,
        "h:mm A"
      ).format("HH:mm");

      const sunday_close = moment(
        updateData.operationHours[0].hourClose,
        "h:mm A"
      ).format("HH:mm");

      const monday_open = moment(
        updateData.operationHours[1].hourOpen,
        "h:mm A"
      ).format("HH:mm");

      const monday_close = moment(
        updateData.operationHours[1].hourClose,
        "h:mm A"
      ).format("HH:mm");

      const tuesday_open = moment(
        updateData.operationHours[2].hourOpen,
        "h:mm A"
      ).format("HH:mm");

      const tuesday_close = moment(
        updateData.operationHours[2].hourClose,
        "h:mm A"
      ).format("HH:mm");

      const wednesday_open = moment(
        updateData.operationHours[3].hourOpen,
        "h:mm A"
      ).format("HH:mm");

      const wednesday_close = moment(
        updateData.operationHours[3].hourClose,
        "h:mm A"
      ).format("HH:mm");

      const thursday_open = moment(
        updateData.operationHours[4].hourOpen,
        "h:mm A"
      ).format("HH:mm");

      const thursday_close = moment(
        updateData.operationHours[4].hourClose,
        "h:mm A"
      ).format("HH:mm");

      const friday_open = moment(
        updateData.operationHours[5].hourOpen,
        "h:mm A"
      ).format("HH:mm");

      const friday_close = moment(
        updateData.operationHours[5].hourClose,
        "h:mm A"
      ).format("HH:mm");

      const saturday_open = moment(
        updateData.operationHours[6].hourOpen,
        "h:mm A"
      ).format("HH:mm");

      const saturday_close = moment(
        updateData.operationHours[6].hourClose,
        "h:mm A"
      ).format("HH:mm");

      setRangeDetail({
        ...rangeDetail,
        name: updateData.name,
        phone_number: updateData.contactInfo.phone_number,
        email: updateData.contactInfo.email,
        lanes: updateData.lanes,
        hour_open: updateData.hour_open,
        hour_close: updateData.hour_close,
        name: updateData.name,
        headline: updateData.headline,
        description: updateData.description,
        policy: updateData.policy,
        street_address: updateData.address.street_address,
        city: updateData.address.city,
        state: updateData.address.state,
        country: updateData.address.country,
        lat: updateData.address.lat,
        lng: updateData.address.long,
        sunday_open,
        sunday_close,
        monday_open,
        monday_close,
        tuesday_open,
        tuesday_close,
        wednesday_open,
        wednesday_close,
        thursday_open,
        thursday_close,
        friday_open,
        friday_close,
        saturday_open,
        saturday_close,
      });

      setIsEdit(true);
      console.log("rangeDetailsUpdated", rangeDetail);
    }
  }, [updateData]);

  const createRange = (e) => {
    e.preventDefault();
    console.log("Submit", rangeDetail);
    console.log("houyrs", operationHours);
    // console.log("open hour", operationHours.${hours_of_operation[1][hour_close]`);
    let hours = {};
    hours[1] = {
      hour_open: operationHours[0],
      hour_close: operationHours[1],
      is_active: 1,
    };
    hours[2] = {
      hour_open: operationHours[2],
      hour_close: operationHours[3],
      is_active: 1,
    };
    hours[3] = {
      hour_open: operationHours[4],
      hour_close: operationHours[5],
      is_active: 1,
    };
    hours[4] = {
      hour_open: operationHours[6],
      hour_close: operationHours[7],
      is_active: 1,
    };
    hours[5] = {
      hour_open: operationHours[8],
      hour_close: operationHours[9],
      is_active: 1,
    };
    hours[6] = {
      hour_open: operationHours[10],
      hour_close: operationHours[11],
      is_active: 1,
    };
    hours[7] = {
      hour_open: operationHours[12],
      hour_close: operationHours[13],
      is_active: 1,
    };
    let data = {
      phone_number: rangeDetail.phone_number,
      email: rangeDetail.email,
      lanes: rangeDetail.lanes,
      hour_open: rangeDetail.hour_open,
      hour_close: rangeDetail.hour_close,
      name: rangeDetail.name,
      headline: rangeDetail.headline,
      description: rangeDetail.description,
      policy: rangeDetail.policy,
      street_address: rangeDetail.street_address,
      city: rangeDetail.city,
      state: rangeDetail.state,
      country: rangeDetail.country,
      lat: rangeDetail.lat,
      lng: rangeDetail.lng,
      zip: "32507",
      hours_of_operation: hours,
    };
    setBtnIsLoading(true);

    if (!isEdit) {
      fetch(API_CREATE_RANGE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                console.error("error", element.message);
                ShowToast("error", element.message);
              });
              setBtnIsLoading(false);
            } else {
              setBtnIsLoading(false);
              ShowToast("error", "Something went wrong!");
            }
          } else {
            reloadData();
            ShowToast("success", "Range Created.");
            closeRightSideBar();
            setBtnIsLoading(false);
          }
        });
    } else {
      data.id = rangeDetail.id;
      fetch(API_EDIT_RANGE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                console.error("error", element.message);
                // ShowToast("error", element.message);
              });
              setBtnIsLoading(false);
            } else {
              setBtnIsLoading(false);
              ShowToast("error", "Something went wrong!");
            }
          } else {
            reloadData();
            ShowToast("success", "Range Updated.");
            closeRightSideBar();
            setBtnIsLoading(false);
          }
        });
    }
  };

  return (
    <>
      <div className={rangeSideBarClass}>
        <header>
          <BackCloseRightBarIcon />
          <h3 className="theme-text-white main-tab-heading-sun mb-0 ps-4">
            {isEdit ? "Update" : "Create"} Range
          </h3>
        </header>
        <div className="body p-5">
          <form class="form-dark">
            <div class="row">
              <div class="col-12">
                <h3>
                  Basic Detail
                  {rangeDetail.sunday_open}
                </h3>
              </div>
              <div class="col-md-3">
                <div class="upload-range_logo position-relative">
                  <input type="file" name="" value="" onChange={rangeForm} />
                  <label>Upload Logo</label>
                </div>
              </div>
              <div class="col-md-9">
                <input
                  type="text"
                  placeholder="Range Name"
                  name="name"
                  value={rangeDetail.name}
                  onChange={rangeForm}
                />
                <input
                  type="text"
                  placeholder="Sub Heading"
                  name="headline"
                  value={rangeDetail.headline}
                  onChange={rangeForm}
                />
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col-md-3">
                <select>
                  <option value="1" selected>
                    Choose Status
                  </option>
                  <option value="2">Available</option>
                  <option value="3">Booked</option>
                  <option value="4">Inactive</option>
                  <option value="5">Maintainance</option>
                </select>
              </div>
              <div class="col-md-3">
                <select>
                  <option value="1" selected>
                    Allow Booking
                  </option>
                  <option value="2">Require Approval</option>
                  <option value="3">Book Immediate</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Bookings Allowed Uptill </label>
                <input
                  type="date"
                  placeholder="Bookings Allowed Uptill"
                  name="hour_open"
                  //   value={rangeDetail.hour_open}
                  onChange={handleDateChange}
                />
              </div>
              <div class="col-md-3">
                <input
                  type="tel"
                  maxlength="10"
                  placeholder="No of Lanes"
                  name="lanes"
                  value={rangeDetail.lanes}
                  onChange={rangeForm}
                />
              </div>
              <div class="col-md-3">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={rangeDetail.email}
                  onChange={rangeForm}
                />
              </div>
              <div class="col-md-3">
                <input
                  type="tel"
                  maxlength="10"
                  placeholder="Contact Number"
                  name="phone_number"
                  value={rangeDetail.phone_number}
                  onChange={rangeForm}
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  placeholder="Website"
                  name="website"
                  value={rangeDetail.website}
                  onChange={rangeForm}
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <textarea
                  name="Description"
                  rows="3"
                  placeholder="Description"
                  defaultValue={rangeDetail.description}
                ></textarea>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <h3>Hours of operation</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 mb-3">
                <div class="bg-opacity-10 bg-secondary rounded p-3 pb-0">
                  <h3 class="mb-0">SUNDAY</h3>
                  <hr />
                  <div>
                    <label for="start-time">Start Time</label>
                    <input
                      name="sunday_open"
                      type="time"
                      defaultValue={rangeDetail.sunday_open}
                      onChange={(e) => handleChangeTime(e, 0)}
                    />
                  </div>
                  <div>
                    <label for="start-time">End Time</label>
                    <input
                      name="sunday_close"
                      type="time"
                      defaultValue={rangeDetail.sunday_close}
                      onChange={(e) => handleChangeTime(e, 1)}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="bg-opacity-10 bg-secondary rounded p-3 pb-0">
                  <h3 class="mb-0">MONDAY</h3>
                  <hr />
                  <div>
                    <label for="start-time">Start Time</label>
                    <input
                      name="monday_open"
                      type="time"
                      defaultValue={rangeDetail.monday_open}
                      onChange={(e) => handleChangeTime(e, 2)}
                    />
                  </div>
                  <div>
                    <label for="start-time">End Time</label>
                    <input
                      name="monday_open"
                      type="time"
                      defaultValue={rangeDetail.monday_close}
                      onChange={(e) => handleChangeTime(e, 3)}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="bg-opacity-10 bg-secondary rounded p-3 pb-0">
                  <h3 class="mb-0">TUESDAY</h3>
                  <hr />
                  <div>
                    <label for="start-time">Start Time</label>
                    <input
                      name="tuesday_open"
                      type="time"
                      defaultValue={rangeDetail.tuesday_open}
                      onChange={(e) => handleChangeTime(e, 4)}
                    />
                  </div>
                  <div>
                    <label for="start-time">End Time</label>
                    <input
                      name="tuesday_close"
                      type="time"
                      defaultValue={rangeDetail.tuesday_close}
                      onChange={(e) => handleChangeTime(e, 5)}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="bg-opacity-10 bg-secondary rounded p-3 pb-0">
                  <h3 class="mb-0">WEDNESDAY</h3>
                  <hr />
                  <div>
                    <label for="start-time">Start Time</label>
                    <input
                      name="wednesday_open"
                      type="time"
                      defaultValue={rangeDetail.wednesday_open}
                      onChange={(e) => handleChangeTime(e, 6)}
                    />
                  </div>
                  <div>
                    <label for="start-time">End Time</label>
                    <input
                      name="wednesday_close"
                      type="time"
                      defaultValue={rangeDetail.wednesday_close}
                      onChange={(e) => handleChangeTime(e, 7)}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="bg-opacity-10 bg-secondary rounded p-3 pb-0">
                  <h3 class="mb-0">THURSDAY</h3>
                  <hr />
                  <div>
                    <label for="start-time">Start Time</label>
                    <input
                      name="thursday_open"
                      type="time"
                      defaultValue={rangeDetail.thursday_open}
                      onChange={(e) => handleChangeTime(e, 8)}
                    />
                  </div>
                  <div>
                    <label for="start-time">End Time</label>
                    <input
                      name="thursday_close"
                      type="time"
                      defaultValue={rangeDetail.thursday_close}
                      onChange={(e) => handleChangeTime(e, 9)}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="bg-opacity-10 bg-secondary rounded p-3 pb-0">
                  <h3 class="mb-0">FRIDAY</h3>
                  <hr />
                  <div>
                    <label for="start-time">Start Time</label>
                    <input
                      name="friday_open"
                      type="time"
                      defaultValue={rangeDetail.friday_open}
                      onChange={(e) => handleChangeTime(e, 10)}
                    />
                  </div>
                  <div>
                    <label for="start-time">End Time</label>
                    <input
                      name="friday_close"
                      type="time"
                      defaultValue={rangeDetail.friday_close}
                      onChange={(e) => handleChangeTime(e, 11)}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="bg-opacity-10 bg-secondary rounded p-3 pb-0">
                  <h3 class="mb-0">SATURDAY</h3>
                  <hr />
                  <div>
                    <label for="start-time">Start Time</label>
                    <input
                      name="saturday_open"
                      type="time"
                      defaultValue={rangeDetail.saturday_open}
                      onChange={(e) => handleChangeTime(e, 12)}
                    />
                  </div>
                  <div>
                    <label for="start-time">End Time</label>
                    <input
                      name="saturday_close"
                      type="time"
                      defaultValue={rangeDetail.saturday_close}
                      onChange={(e) => handleChangeTime(e, 13)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <h3>Location</h3>
              </div>
              <div class="col-md-3">
                <GooglePlacesAutocomplete
                  //   searchOptions={{ types: ["country"] }}
                  //   apiOptions={{ searchOptions: { types: ["country"] } }}
                  selectProps={{
                    location,
                    onChange: handleLocationChange,
                  }}
                  autocompletionRequest={{}}
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                />
              </div>
              <div class="col-md-3">
                <input
                  name="city"
                  type="text"
                  readOnly
                  defaultValue={rangeDetail.city}
                />
              </div>
              <div class="col-md-3">
                <input
                  name="state"
                  type="text"
                  readOnly
                  defaultValue={rangeDetail.state}
                />
              </div>
              <div class="col-md-3">
                <input
                  name="country"
                  type="text"
                  readOnly
                  defaultValue={rangeDetail.country}
                />
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <h3>Gallery</h3>
              </div>
              <div class="col-md-3">
                <input
                  name="upload_image"
                  type="file"
                  value=""
                  onChange={rangeForm}
                />
                <label for="upload_image">Upload Images</label>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <h3>Policy</h3>
              </div>
              <div class="col-12">
                <textarea
                  name="policy"
                  rows="10"
                  value={rangeDetail.policy}
                  onChange={rangeForm}
                ></textarea>
              </div>
            </div>
            <button
              class="theme-button mt-5"
              disabled={isBtnLoading}
              onClick={(e) => createRange(e)}
            >
              {isEdit ? "Update" : "Create"} Range{" "}
              {isBtnLoading ? (
                <>
                  <i className="cstm-loader"></i>
                </>
              ) : (
                ""
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateRange;
