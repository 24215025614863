import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AddIcon, BackCloseRightBarIcon, SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';
import { API_CREATE_COURSE } from '../../../config/Endpoints';

const Employees = () => {

    const [rightSideBarOpen, setSidebarClass] = useState('right-side-bar employee-detail g-0 overflow-auto');
    const [addEmployeeForm, setEmployeeForm] = useState('right-side-bar g-0 overflow-auto');

    const detailViewOpen = () => {
        setSidebarClass(prevClass => prevClass + ' open');
        document.body.classList.add('overlay')
    };

    const openEmployeeForm = () => {
        setEmployeeForm(prevClass => prevClass + ' open');
        document.body.classList.add('overlay')
    };

    const [employeeDetail, setEmployeeDetail] = React.useState({
        name: "",
    })

    function employeeForm(evt) {
        const value = evt.target.value;
        console.log(value)
        setEmployeeDetail({
            ...employeeDetail,
            [evt.target.name]: value
        });
        console.log(employeeDetail)
    }

    const createEmployee = (e) => {
        e.preventDefault();

        fetch(API_CREATE_COURSE, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(employeeDetail),
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success) {
                    let errorList = data.error.fieldErrors;
                    console.log(errorList);
                } else {
                }
            });
    }

    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <Link onClick={openEmployeeForm}>
                            <AddIcon />
                        </Link>
                        <h3>Employees</h3>
                        <ul>
                            <li><Link to={'/employees'}><span className="theme-text">Employees</span></Link></li>
                            <li><Link to={'/tasks'}>Tasks</Link></li>
                            <li><Link to={'/timesheet'}>Timesheet</Link></li>
                        </ul>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Employee Name</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                            <th>Status</th>
                                            <th className="text-center">POS Access</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>John Doe</td>
                                            <td>+1 478 9984 894</td>
                                            <td>john.doe@example.com</td>
                                            <td><span className="activity online"></span> Online</td>
                                            <td className="text-center cstm-checkbox">
                                                <input type="checkbox" />
                                            </td>
                                            <td className="theme-text text-center" onClick={detailViewOpen}>
                                                <Link>View</Link>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className={rightSideBarOpen}>
                        <header>
                            <BackCloseRightBarIcon />
                            <h3 className="theme-text-white main-tab-heading-sun mb-0 ps-4">Employee Detail</h3>
                        </header>
                        <div className="body p-5">
                            <div className="row">
                                <div className="col-12">
                                    <h3>Personal Imformation</h3>
                                </div>
                                <div className="col-lg-6">
                                    <p><span>First Name :</span> John Doe</p>
                                    <p><span>Father's Name :</span> kenneth Doe</p>
                                    <p><span>Mobile Number :</span> +1 812 344-3709</p>
                                </div>
                                <div className="col-lg-6">
                                    <p><span>D.O.B :</span> July 16, 1983</p>
                                    <p>
                                        <span>Address :</span>
                                        <span>1408 Kickapoo Creek Rd <br />Bloomington 61705 <br />ILLINOIS <br />United States</span>
                                    </p>
                                </div>
                                <hr className="mt-3 mb-4" />
                                <div className="col-12">
                                    <h3>Professional Information</h3>
                                </div>
                                <div className="col-12 d-flex flex-wrap gap-3">
                                    <a href="#!" title="Download" download="download" className="bg-light bg-opacity-10 px-2 py-1 rounded-2">John Doe Resume <i className="ms-3"> </i></a>
                                    <a href="#!" title="Download" download="download" className="bg-light bg-opacity-10 px-2 py-1 rounded-2">Education Certificates <i className="ms-3"> </i></a>
                                    <a href="#!" title="Download" download="download" className="bg-light bg-opacity-10 px-2 py-1 rounded-2">Experience Certificates <i className="ms-3"> </i></a>
                                </div>
                                <hr className="mt-3 mb-4" />
                                <div className="col-12">
                                    <h3>Financial Information</h3>
                                </div>
                                <div className="col-lg-6">
                                    <p><span>Bank Name :</span> American Xpress</p>
                                    <p><span>Account Holder :</span> John Doe</p>
                                    <p><span>IFS Code :</span> AMDJ38293H</p>
                                </div>
                                <div className="col-lg-6">
                                    <p>
                                        <span>Address :</span>
                                        <span>1408 Kickapoo Creek Rd <br />Bloomington 61705 <br />ILLINOIS <br />United States</span>
                                    </p>
                                </div>
                                <hr className="mt-3 mb-4" />
                                <div className="col-12">
                                    <h3>Job Information</h3>
                                </div>
                                <div className="col-lg-6">
                                    <p><span>Designation :</span> Sr. Sales Manager</p>
                                    <p><span>Role :</span> Sales</p>
                                </div>
                                <div className="col-lg-6">
                                    <p><span>Salary :</span> $50,543</p>
                                </div>
                                <hr className="mt-3 mb-4" />
                                <div className="col-12">
                                    <h3>Permissions</h3>
                                </div>
                                <div className="col-lg-4">
                                    <p><span>Employee Module :</span> Read</p>
                                </div>
                                <div className="col-lg-4">
                                    <p><span>Training Module :</span> Write</p>
                                </div>
                                <div className="col-lg-4">
                                    <p><span>Firearms Module :</span> Read</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={addEmployeeForm}>
                        <header>
                            <BackCloseRightBarIcon />
                            <h3 className="theme-text-white main-tab-heading-sun mb-0 ps-4">Add Employee</h3>
                        </header>
                        <div className="body p-5">
                            <form class="form-dark">
                                <div class="row">
                                    <div class="col-12">
                                        <h3>Personal Imformation</h3>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" placeholder="Full Name" name='name' value={employeeDetail.name} onChange={employeeForm} />
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" placeholder="Father's Name" />
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" placeholder="DOB (DD/MM/YYYY)" />
                                    </div>
                                    <div class="col-md-9 col-lg-8">
                                        <input type="text" placeholder="Address" />
                                    </div>
                                    <div class="col-md-4">
                                        <input type="tel" maxlength="10" placeholder="Mobile Number" />
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-12">
                                        <h3>Professional Information</h3>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="file" name="upload-resume" />
                                        <label for="upload-resume">Upload Resume</label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="file" name="qualifications" />
                                        <label for="qualifications">Qualifications</label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="file" name="experience" />
                                        <label for="experience">Experiences</label>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-12">
                                        <h3>Financial Information</h3>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" placeholder="Choose Bank" />
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" placeholder="Account Name" />
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" placeholder="IFS Code" />
                                    </div>
                                    <div class="col-12">
                                        <input type="text" placeholder="Address" />
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-12">
                                        <h3>Job Information</h3>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" placeholder="Designation" />
                                    </div>
                                    <div class="col-md-3">
                                        <input type="text" placeholder="Choose Project" />
                                    </div>
                                    <div class="col-md-3">
                                        <input type="text" placeholder="Choose Role" />
                                    </div>
                                    <div class="col-md-4">
                                        <textarea placeholder="Salary Details" rows="4"></textarea>
                                    </div>
                                    <div class="col-md-4">
                                        <textarea placeholder="Promotion History" rows="4"></textarea>
                                    </div>
                                    <div class="col-md-4">
                                        <textarea placeholder="Project History" rows="4"></textarea>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-12">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Permissions</th>
                                                    <th class="text-center">Read</th>
                                                    <th class="text-center">Write</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Employee Module</td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Training Module</td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Firearms Module</td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Range Module</td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>POS Access</td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                    <td class="text-center cstm-checkbox">
                                                        <input type="checkbox" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <button class="theme-button mt-5" onClick={(e) => createEmployee(e)}>Create Employee </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Employees;