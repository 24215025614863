let baseUrl = process.env.REACT_APP_API_ENDPOINT + "api/web/";

export const API_LOGIN_STEP_1 =
  baseUrl + "v1/new-login-flow/generate-login-process";
export const API_LOGIN_STEP_2 = baseUrl + "v1/new-login-flow/new-login";
export const API_USER_PROFILE = baseUrl + "v1/user/profile";
export const API_EDIT_PROFILE = baseUrl + "v1/auth/update-profile";
export const API_CREATE_COURSE = baseUrl + "v1/course/create-course";
export const API_EDIT_COURSE = baseUrl + "v1/course/update-course";
export const API_LIST_COURSE = baseUrl + "v1/course/search-course";
export const API_VIEW_COURSE = baseUrl + "v1/course/view";
export const API_CREATE_RANGE = baseUrl + "v1/range/create-range";
export const API_EDIT_RANGE = baseUrl + "v1/range/update-range";
export const API_LIST_RANGE = baseUrl + "v1/range/search-range";
export const API_VIEW_RANGE = baseUrl + "v1/range/view";
