import React, { useState, useEffect } from "react";
import { SideBarIcon } from "../../components/TopHeader/TopHeader";
import { API_EDIT_PROFILE, API_USER_PROFILE } from "../../config/Endpoints";
import { ShowToast } from "../../helpers/Helpers";
import SidebarMenu from "../../layouts/SidebarMenu/SidebarMenu";

const Profile = () => {
  const [userValues, setUserValues] = useState({});
  const [isBtnLoading, setBtnIsLoading] = useState(false);

  useEffect(() => {
    fetchUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUser = async () => {
    await fetch(API_USER_PROFILE, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((userData) => {
        if (!userData.success) {
        } else {
          console.log(userData.data);
          setUserValues({
            ...userValues,
            first_name: userData.data.profile.firstName,
            last_name: userData.data.profile.lastName,
            phone_1: userData.data.profile.phone1,
            email: userData.data.email,
            username: userData.data.userName,
          });
          console.log(userValues);
        }
      });
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setUserValues({
      ...userValues,
      [evt.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnIsLoading(true);
    fetch(API_EDIT_PROFILE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userValues),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          if (errorList) {
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
            setBtnIsLoading(false);
          } else {
            setBtnIsLoading(false);
            ShowToast("error", "Something went wrong!");
          }
        } else {
          fetchUser();
          ShowToast("success", "Details Updated.");
          setBtnIsLoading(false);
        }
      });
  };

  return (
    <>
      <div className="wrapper d-flex">
        <SidebarMenu />
        <div className="wrapper-body">
          <div className="top-header pe-4">
            <SideBarIcon />
            <h3>Profile Detail</h3>
          </div>
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-md-8">
                <form className="form-dark">
                  <div className="card px-4 py-3">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="username">Username</label>
                        <input
                          type="text"
                          name="username"
                          value={userValues.username}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="username">First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          value={userValues.first_name}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          value={userValues.last_name}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone1">Phone Number</label>
                        <input
                          type="tel"
                          name="phone1"
                          value={userValues.phone_1}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          name="email"
                          value={userValues.email}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          name="address"
                          value={userValues.address}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="department">Department</label>
                        <input type="text" name="department" value="-" />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="role">Role</label>
                        <input type="text" name="role" value="-" />
                      </div>
                    </div>
                  </div>

                  <>
                    <button
                      className="theme-button"
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Save
                      {isBtnLoading ? (
                        <>
                          <i className="cstm-loader"></i>
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                  </>

                  {/* <button onClick={() => setEditing(true)} type="button" className="theme-button">Update Profile</button>
                  <button type="submit" className="theme-button">Save</button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
