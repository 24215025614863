import React from 'react';
import { SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';
import Tabs from "./Tabs"

const Firearms = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <h3>Firearms Management</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                            <div class="large-icon-buttons mt-5">
                                    <Tabs
                                        path={'/manage-inventory'}
                                        imgpath={'./images/icons/manage-inventory.svg'}
                                        tabtitle={'Manage Inventory'}
                                    />
                                    <Tabs
                                        path={'/manage-inventory'}
                                        imgpath={'./images/icons/rental-inventory.svg'}
                                        tabtitle={'Rental Inventory'}
                                    />
                                    <Tabs
                                        path={'/boundbook'}
                                        imgpath={'./images/icons/manage-bound-book.svg'}
                                        tabtitle={'Manage BoundBook'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Firearms;