import React, { useState, useRef} from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import SimpleReactValidator from "simple-react-validator";
import { API_LOGIN_STEP_1, API_LOGIN_STEP_2 } from "../../config/Endpoints";
import { ShowToast, history } from "../../helpers/Helpers";
import { setUserData } from "../../helpers/Storage";

const Login = (props) => {
  const [isBtnLoading, setBtnIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationToken, setConfirmationToken] = useState("");
  const [step, setStep] = useState(1);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const handleNext = (e) => {
    e.preventDefault();
    console.log(phoneNumber);
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return false;
    }
    const data = {
      phone_number: phoneNumber,
      country: "US",
      timezone: "America/New_York",
      language: "en-US",
    };
    setBtnIsLoading(true);
    fetch(API_LOGIN_STEP_1, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          errorList.forEach((element) => {
            ShowToast("error", element.message);
          });
          setBtnIsLoading(false);
        } else {
          ShowToast("success", "OTP sent to your phone number.");
          setBtnIsLoading(false);
          setStep(2);
          setConfirmationToken(data.data.confirmationToken);
        }
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    console.log(phoneNumber);
    console.log(confirmationToken);
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return false;
    }
    const data = {
      phone_number: phoneNumber,
      country: "US",
      timezone: "America/New_York",
      language: "en-US",
      confirmation_token: confirmationToken,
      confirmation_numbers: otp,
    };
    setBtnIsLoading(true);
    fetch(API_LOGIN_STEP_2, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          if (errorList.confirmation_numbers) {
            ShowToast("error", errorList.confirmation_numbers);
          }
          setBtnIsLoading(false);
        } else {
          setUserData(data);
          ShowToast("success", "Logged in.");
          setBtnIsLoading(false);
          history.push("/dashboard");
          document.location.reload();
        }
      });
  };
  return (
    <section className="login">
      <div className="login_left-side">
        <img src="./images/Logo.png" alt="Logo" className="img-fluid" />
        <p>Development Version 1.0</p>
      </div>
      <div className="login_right-side">
        <h2>Welcome</h2>
        {step === 1 ? (
          <>
            {" "}
            <form className="form-dark">
              <PhoneInput
                name="phone_number"
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                value={phoneNumber}
                onChange={setPhoneNumber}
                className="mb-3"
              />
              {simpleValidator.current.message(
                "phoneNumber",
                phoneNumber,
                "required"
              )}

              <button
                typeof="submit"
                onClick={(e) => handleNext(e)}
                disabled={isBtnLoading}
                className="theme-button"
              >
                Next {isBtnLoading ? <><i className='cstm-loader'></i></> : ""}
              </button>
            </form>
          </>
        ) : (
          <>
            <form className="form-dark">
              <input
                type="text"
                placeholder="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              {simpleValidator.current.message("otp", otp, "required")}
              <button
                typeof="submit"
                onClick={(e) => handleLogin(e)}
                disabled={isBtnLoading}
                className="theme-button"
              >
                Log In {isBtnLoading ? <><i className='cstm-loader'></i></> : ""}
              </button>
            </form>
          </>
        )}
      </div>
    </section>
  );
};

export default Login;
