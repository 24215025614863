import React from "react";
import { Link } from "react-router-dom";
import { BackIcon, SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const InventoryDetail = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <BackIcon path={'/manage-inventory'} />
                        <h3>Inventory Detail</h3>
                    </div>
                    <div className="events">
                        <div className="container-fluid p-4">
                            <div className="row">
                                <div className="col-md-8 events__border-right">
                                    <div className="d-flex">
                                        <img src="./images/gun-1.jpg" alt="Glock" className="me-4 inventory__title-image" />
                                        <div>
                                            <h2 className="events__title">Glock 19 Gen5 9mm 4.02” Barrel 15+1</h2>
                                            <p className="theme-text-blue-grey">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, temporibus impedit sit unde quidem nam suscipit quis deserunt facere asperiores quos aspernatur quo vel officia? Tempora veniam aut voluptatibus fugit.</p>
                                            <p><span className="bg-info bg-opacity-50 p-1 px-3 rounded text-white small">Rented</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <h3 className="fs-6 theme-text-blue-grey d-flex">Key Highlights </h3>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Model</td>
                                                <td>HCP9379BOSP</td>
                                            </tr>
                                            <tr>
                                                <td>MFG</td>
                                                <td>Springfield Armory</td>
                                            </tr>
                                            <tr>
                                                <td>Cost</td>
                                                <td>$564.37</td>
                                            </tr>
                                            <tr>
                                                <td>Date Received</td>
                                                <td>Oct 12, 2022</td>
                                            </tr>
                                            <tr>
                                                <td>Days On Hand</td>
                                                <td>14</td>
                                            </tr>
                                            <tr>
                                                <td>Date Sold</td>
                                                <td>Not Sold</td>
                                            </tr>
                                            <tr>
                                                <td>Age</td>
                                                <td>3 years</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12">
                                    <hr />
                                    <h3 className="fs-6 theme-text-blue-grey d-flex">Inventory Report</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Purpose</th>
                                                <th>Delivery Date</th>
                                                <th>Period</th>
                                                <th>Receive Date</th>
                                                <th>Inventory Age</th>
                                                <th>Rent</th>
                                                <th className="text-center">Amount Received</th>
                                                <th className="text-center">Amount Pending</th>
                                                <th className="text-center">Documents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>John Smith</td>
                                                <td>Rent</td>
                                                <td>Oct 28, 2022</td>
                                                <td>10 days</td>
                                                <td>-</td>
                                                <td>3 years</td>
                                                <td align="center">$30</td>
                                                <td align="center">$50</td>
                                                <td align="center">$0</td>
                                                <td align="center" className="theme-text">
                                                    <Link to={''}>view</Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Laurie Peterson</td>
                                                <td>Layaway</td>
                                                <td>July 10, 2022</td>
                                                <td>2 months</td>
                                                <td>Sep 08, 2022</td>
                                                <td>2 years</td>
                                                <td align="center">-</td>
                                                <td align="center">$100</td>
                                                <td align="center">$200</td>
                                                <td align="center" className="theme-text">
                                                    <Link to={''}>view</Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <br />
                                    <h3 className="fs-6 theme-text-blue-grey d-flex">Work Orders (gunsmithing)</h3>
                                    <p>No Records Found</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default InventoryDetail;