import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
};

export const revenueOptions = {
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true
        }
    }
}

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const employeeData = {
    labels: months,
    datasets: [
        {
            label: 'No. of Employees Active',
            backgroundColor: '#eb7c45',
            borderColor: '#eb7c45',
            data: [0, 25, 22, 24, 32, 30, 36, 30, 34],
        }
    ],
};

export function EmployeeChart() {
    return <Line options={options} data={employeeData} />;
}


export const revenueData = {
    labels: ['Jan', 'June', 'Dec'],
    datasets: [
        {
            label: 'Online',
            data: [2894, 4783, 8038],
            backgroundColor: ['rgba(255, 99, 132, 0.2)'],
            borderColor: ['rgb(255, 99, 132)'],
            borderWidth: 1
        },
        {
            label: 'Offline',
            data: [6500, 5920, 6357],
            backgroundColor: ['rgba(255, 159, 64, 0.2)'],
            borderColor: ['rgb(255, 159, 64)'],
            borderWidth: 1
        }
    ]
};

export function RevenueChart() {
    return <Bar options={revenueOptions} data={revenueData} />;
}

