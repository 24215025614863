import React from 'react';
import { Link } from 'react-router-dom';
import { AddIcon, SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

function employeeDetailViewOpen() {
    var element = document.getElementsByClassName("right-side-bar")[0];
    element.classList.addClass("open");
}

function employeeDetailViewClose() {
    var element = document.getElementsByClassName("right-side-bar")[0];
    element.classList.removeClass("open");
}

const Tasks = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <AddIcon />
                        <h3>Tasks</h3>
                        <ul>
                            <li><Link to={'/employees'}>Employees</Link></li>
                            <li><Link to={'/tasks'}> <span className="theme-text">Tasks</span> </Link> </li>
                            <li><Link to={'/timesheet'}>Timesheet</Link></li>
                        </ul>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="70"></th>
                                            <th>Title</th>
                                            <th width="300">Description</th>
                                            <th className="text-center">Assignee</th>
                                            <th className="text-center">Priority</th>
                                            <th className="text-center">Status</th>
                                            <th width="140" className="text-center">Due Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center cstm-checkbox">
                                                <input type="checkbox" title="Mark Complete" />
                                            </td>
                                            <td>This is a task and it is should be done by today itself.</td>
                                            <td className="opacity-50">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam perferendis
                                                autem magni enim
                                                iure asperiores velit, odit doloremque ullam consequatur.</td>
                                            <td className="text-center text-nowrap">Laurie Peterson</td>
                                            <td align="center"><label className="bg-danger bg-opacity-50 p-1 px-3 rounded">High</label></td>
                                            <td className="text-center text-warning">In&nbsp;Progress</td>
                                            <td className="text-center">Aug 05, 2022</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center cstm-checkbox">
                                                <input type="checkbox" title="Mark Complete" />
                                            </td>
                                            <td>This is a task and it is should be done by today itself.</td>
                                            <td className="opacity-50">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam perferendis
                                                autem magni enim
                                                iure asperiores velit, odit doloremque ullam consequatur.</td>
                                            <td className="text-center text-nowrap">Laurie Peterson</td>
                                            <td align="center"><label className="bg-warning bg-opacity-50 p-1 px-3 rounded">Low</label></td>
                                            <td className="text-center text-info">Completed</td>
                                            <td className="text-center">Aug 05, 2022</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center cstm-checkbox">
                                                <input type="checkbox" title="Mark Complete" />
                                            </td>
                                            <td>This is a task and it is should be done by today itself.</td>
                                            <td className="opacity-50">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam perferendis
                                                autem magni enim
                                                iure asperiores velit, odit doloremque ullam consequatur.</td>
                                            <td className="text-center text-nowrap">Laurie Peterson</td>
                                            <td align="center"><label className="bg-info bg-opacity-50 p-1 px-3 rounded">Medium</label></td>
                                            <td className="text-center text-warning">Overdue</td>
                                            <td className="text-center"><label className="bg-danger bg-opacity-50 p-1 px-3 rounded">Aug 05, 2022</label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="right-side-bar employee-detail g-0 overflow-auto">

                    </div>
                </div>
            </div>
        </>
    );
};

export default Tasks;