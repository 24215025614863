import React from "react";
import { Link } from "react-router-dom";
import { BackIcon, SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const ManageInventory = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <BackIcon path={'/firearms'} />
                        <h3>Manage Inventory</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="inventories">
                                    <div className="item">
                                        <Link to={'/inventory-detail'}>
                                            <div>
                                                <img src="./images/gun-1.jpg" alt="Gun Name" className="img-fluid" />
                                                <p className="status rented">RENTED</p>
                                            </div>
                                            <div style={{flex: 1}}>
                                                <h3>Glock 19 Gen5 9mm 4.02” Barrel 15+1</h3>
                                                <div className="specifications">
                                                    <div>
                                                        <p>Model: HCP9379BOSP</p>
                                                        <p>MFG: Springfield Armory</p>
                                                    </div>
                                                    <p className="price">$564.37</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="item">
                                    <Link to={'/inventory-detail'}>
                                        <div>
                                            <img src="./images/gun-1.jpg" alt="Gun Name" className="img-fluid" />
                                            <p className="status rented">RENTED</p>
                                        </div>
                                        <div style={{flex: 1}}>
                                            <h3>Glock 19 Gen5 9mm 4.02” Barrel 15+1</h3>
                                            <div className="specifications">
                                                <div>
                                                    <p>Model: HCP9379BOSP</p>
                                                    <p>MFG: Springfield Armory</p>
                                                </div>
                                                <p className="price">$564.37</p>
                                            </div>
                                        </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                    <Link to={'/inventory-detail'}>
                                        <div>
                                            <img src="./images/gun-1.jpg" alt="Gun Name" className="img-fluid" />
                                            <p className="status rented">RENTED</p>
                                        </div>
                                        <div style={{flex: 1}}>
                                            <h3>Glock 19 Gen5 9mm 4.02” Barrel 15+1</h3>
                                            <div className="specifications">
                                                <div>
                                                    <p>Model: HCP9379BOSP</p>
                                                    <p>MFG: Springfield Armory</p>
                                                </div>
                                                <p className="price">$564.37</p>
                                            </div>
                                        </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                    <Link to={'/inventory-detail'}>
                                        <div>
                                            <img src="./images/gun-1.jpg" alt="Gun Name" className="img-fluid" />
                                            <p className="status work-order">Work Order</p>
                                        </div>
                                        <div style={{flex: 1}}>
                                            <h3>Glock 19 Gen5 9mm 4.02” Barrel 15+1</h3>
                                            <div className="specifications">
                                                <div>
                                                    <p>Model: HCP9379BOSP</p>
                                                    <p>MFG: Springfield Armory</p>
                                                </div>
                                                <p className="price">$564.37</p>
                                            </div>
                                        </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                    <Link to={'/inventory-detail'}>
                                        <div>
                                            <img src="./images/gun-1.jpg" alt="Gun Name" className="img-fluid" />
                                            <p className="status rented">RENTED</p>
                                        </div>
                                        <div style={{flex: 1}}>
                                            <h3>Glock 19 Gen5 9mm 4.02” Barrel 15+1</h3>
                                            <div className="specifications">
                                                <div>
                                                    <p>Model: HCP9379BOSP</p>
                                                    <p>MFG: Springfield Armory</p>
                                                </div>
                                                <p className="price">$564.37</p>
                                            </div>
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ManageInventory;