import React from "react";
import { Link } from "react-router-dom";
import { logoutUser } from "../../helpers/Storage";
import { ShowToast, history } from "../../helpers/Helpers";

const SidebarMenu = () => {
  const handleLogout = (e) => {
    logoutUser();
    ShowToast("success", "Logged out.");
    history.push("/");
    document.location.reload();
  };
  return (
    <>
      <div className="side-bar">
        <nav className="theme-text-blue-grey theme-paragraph">
          <ul>
            <li className="active theme-text">
              <Link to={"/"}>
                <img
                  width={12}
                  src="/images/icons/dashboard_active.svg"
                  alt="Dashbaord"
                />{" "}
                Dashboard
              </Link>
            </li>
            <li className="disabled">
              <Link to={"/employees"}>
                <img
                  width={12}
                  src="/images/icons/customer_inactive_icon.svg"
                  alt="Employees"
                />{" "}
                Employees
              </Link>
            </li>
            <li>
              <Link to={"/training"}>
                <img
                  width={12}
                  src="/images/icons/customer_inactive_icon.svg"
                  alt="Training"
                />{" "}
                Training
              </Link>
            </li>
            <li className="disabled">
              <Link to={"/categories"}>
                <img
                  width={12}
                  src="/images/icons/products_inactive_icon.svg"
                  alt="Products"
                />{" "}
                Products
              </Link>
            </li>
            <li>
              <Link to={"/ranges"}>
                <img
                  width={12}
                  src="/images/icons/order_inactive_icon.svg"
                  alt="Ranges"
                />{" "}
                Ranges
              </Link>
            </li>
            <li className="disabled">
              <Link to={"/firearms"}>
                <img
                  width={12}
                  src="/images/icons/order_inactive_icon.svg"
                  alt="Firearms"
                />{" "}
                Firearms
              </Link>
            </li>
            <li>
              <Link to={"/orders"}>
                <img
                  width={12}
                  src="/images/icons/order_inactive_icon.svg"
                  alt="Orders"
                />{" "}
                Orders
              </Link>
            </li>
            <li className="disabled">
              <Link to={"/membership"}>
                <img
                  width={12}
                  src="/images/icons/wallet_inactive_icon.svg"
                  alt="Membership"
                />{" "}
                Membership
              </Link>
            </li>
            <li className="disabled">
              <Link to={"/discounts"}>
                <img
                  width={12}
                  src="/images/icons/subscription_inactive_icon.svg"
                  alt="Discounts"
                />{" "}
                Discounts
              </Link>
            </li>
            <li className="disabled">
              <Link to={"/notifications"}>
                <img
                  width={12}
                  src="/images/icons/setting_inactive.svg"
                  alt="Notifications"
                />{" "}
                Notifications
              </Link>
            </li>
            <li>
              <Link onClick={handleLogout}>
                <img
                  width={12}
                  src="/images/icons/setting_inactive.svg"
                  alt="Logout"
                />{" "}
                Logout
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default SidebarMenu;
