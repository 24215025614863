import React from 'react';
import { BackIcon, SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const MembershipDetail = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <BackIcon path={'/membership'} />
                        <h3>Midtown Plans</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3 className='theme-label'><b>Individual</b></h3>
                                <div className="card">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>10% Off Ammunition</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>10% Off Ammunition</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td>Unlimited & Priority Range Shooting</td>
                                                <td>Yes</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <h3 className='theme-label'><b>Members</b></h3>
                                <div className="card">
                                    <div className="members">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jose Murrye</td>
                                                    <td>
                                                        <h6>Tag name one</h6>
                                                    </td>
                                                    <td className="new-border">
                                                        <img src="./images/icons/email_icon.svg" alt="" className="px-3" />
                                                        <img src="./images/icons/message_icon.svg" alt="" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MembershipDetail;