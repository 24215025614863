import { render } from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/manager/Dashbaord/Dashaboard";
import Employees from "./pages/manager/Employees/Employees";
import Tasks from "./pages/manager/Employees/Tasks";
import Timesheet from "./pages/manager/Employees/Timesheet";
import Training from "./pages/manager/Training/Training";
import Categories from "./pages/manager/Products/Categories";
import Products from "./pages/manager/Products/Products";
import Ranges from "./pages/manager/Ranges/Ranges";
import ManageRanges from "./pages/manager/Ranges/ManageRanges";
import RangeDetail from "./pages/manager/Ranges/RangeDetail";
import Firearms from "./pages/manager/Firearms/Firearms";
import BoundBook from "./pages/manager/Firearms/BoundBook";
import ManageInventory from "./pages/manager/Firearms/ManageInventory";
import Membership from "./pages/manager/Membership/Membership";
import Discounts from "./pages/manager/Discounts/Discounts";
import Notifications from "./pages/manager/Notifications/Notifications";
import MembershipDetail from "./pages/manager/Membership/MembershipDetail";
import CourseDetailPage from "./pages/manager/Training/CourseDetailPage";
import Instructors from "./pages/manager/Training/Instructors";
import ProductDetail from "./pages/manager/Products/ProductDetail";
import InventoryDetail from "./pages/manager/Firearms/InventoryDetail";
import Login from "./pages/Login/Login";
import "./styles/bootstrap/index.scss";
import "./styles/theme.scss";
import { history } from "./helpers/Helpers";
import { AuthInterceptor } from "./helpers/AuthInterceptor";
import {
  getUserData,
} from "./helpers/Storage";
import Profile from "./pages/Profile/Profile";
import Lanes from "./pages/manager/Ranges/Lanes/Lanes";
import OrderListing from "./pages/manager/Orders/OrderListing";
import OrderDetail from "./pages/manager/Orders/OrderDetail";

AuthInterceptor();
// CheckSession();
function CheckLoggedIn({ children, redirectTo }) {
  let userData = getUserData();
  let isLoggedIn = userData.isLoggedIn;
  return isLoggedIn ? <Navigate to={redirectTo} /> : children;
}
function RequireAuth({ children, redirectTo }) {
  let userData = getUserData();
  let isLoggedIn = userData.isLoggedIn;
  return isLoggedIn ? children : <Navigate to={redirectTo} />;
}
function App() {
  render(
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <BrowserRouter forceRefresh={true} history={history}>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <RequireAuth redirectTo="/">
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth redirectTo="/">
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/training"
            element={
              <RequireAuth redirectTo="/">
                <Training />
              </RequireAuth>
            }
          />
          <Route
            path="/training-detail/:id"
            element={
              <RequireAuth redirectTo="/">
                <CourseDetailPage />
              </RequireAuth>
            }
          />
          <Route
            path="/ranges"
            element={
              <RequireAuth redirectTo="/">
                <Ranges />
              </RequireAuth>
            }
          />
          <Route
            path="/manage-ranges"
            element={
              <RequireAuth redirectTo="/">
                <ManageRanges />
              </RequireAuth>
            }
          />
          <Route
            path="/range-detail/:id"
            element={
              <RequireAuth redirectTo="/">
                <RangeDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/orders"
            element={
              <RequireAuth redirectTo="/">
                <OrderListing />
              </RequireAuth>
            }
          />
          <Route path="/employees" element={<Employees />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/timesheet" element={<Timesheet />} />
          <Route path="/instructor" element={<Instructors />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product-detail" element={<ProductDetail />} />
          <Route path="/firearms" element={<Firearms />} />
          <Route path="/manage-inventory" element={<ManageInventory />} />
          <Route path="/inventory-detail" element={<InventoryDetail />} />
          <Route path="/boundbook" element={<BoundBook />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/membership-detail" element={<MembershipDetail />} />
          <Route path="/discounts" element={<Discounts />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/lanes" element={<Lanes />} />
          <Route path="/order-detail" element={<OrderDetail />} />

          <Route
            path="/"
            exact
            element={
              <CheckLoggedIn redirectTo="/dashboard">
                <Login />
              </CheckLoggedIn>
            }
          />
        </Routes>
      </BrowserRouter>
    </>,
    document.getElementById("root")
  );
}

export default App;
