import React, { useState, useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import {
  BackCloseRightBarIcon,
  closeRightSideBar,
} from "../../../components/TopHeader/TopHeader";
import { API_CREATE_COURSE, API_EDIT_COURSE } from "../../../config/Endpoints";
import { ShowToast } from "../../../helpers/Helpers";

const CreateCourse = (props) => {
  const courseSideBarClass = props.courseSideBarClass;
  const updateData = props.updateData;
  const reloadData = props.reloadData;
  const [isEdit, setIsEdit] = useState(false);
  const [isBtnLoading, setBtnIsLoading] = useState(false);
  const [courseDetail, setCourseDetail] = useState({
    name: "",
    headline: "",
    description: "",
    trainer_ids: "",
    is_live: "",
    amount: 200,
    status: 1,
    range_id: 1,
    zip: "32507",
    startDate: new Date(),
    street_address: "abc",
  });

  useEffect(() => {
    if (
      typeof updateData !== "undefined" &&
      typeof updateData.id !== "undefined"
    ) {
      setCourseDetail(updateData);
      setIsEdit(true);
    }
  }, [updateData]);

  const courseForm = (evt) => {
    const value = evt.target.value;
    setCourseDetail({
      ...courseDetail,
      [evt.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnIsLoading(true);
    if (!isEdit) {
      fetch(API_CREATE_COURSE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(courseDetail),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
              setBtnIsLoading(false);
            } else {
              setBtnIsLoading(false);
              ShowToast("error", "Something went wrong!");
            }
          } else {
            reloadData();
            ShowToast("success", "Course Created.");
            closeRightSideBar();
            setBtnIsLoading(false);
          }
        });
    } else {
      fetch(API_EDIT_COURSE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(courseDetail),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            let errorList = data.error.fieldErrors;
            if (errorList) {
              errorList.forEach((element) => {
                ShowToast("error", element.message);
              });
              setBtnIsLoading(false);
            } else {
              setBtnIsLoading(false);
              ShowToast("error", "Something went wrong!");
            }
          } else {
            reloadData();
            ShowToast("success", "Course Updated.");
            closeRightSideBar();
            setBtnIsLoading(false);
          }
        });
    }
  };

  return (
    <>
      <div className={courseSideBarClass}>
        <header>
          <BackCloseRightBarIcon />
          <h3 className="theme-text-white main-tab-heading-sun mb-0 ps-4">
            {isEdit ? "Update" : "Create"} Course
          </h3>
        </header>
        <div className="body p-5">
          <form class="form-dark">
            <div class="row">
              <div class="col-12">
                <h3>Course Imformation</h3>
              </div>
              <div class="col-12">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={courseDetail.name}
                  onChange={courseForm}
                />
              </div>
              <div class="col-12">
                <textarea
                  rows="3"
                  placeholder="Headline"
                  name="headline"
                  value={courseDetail.headline}
                  onChange={courseForm}
                ></textarea>
              </div>
              <div class="col-md-6 col-lg-4">
                <input
                  type="file"
                  name="upload-photo"
                  value=""
                  onChange={courseForm}
                />
                <label htmlFor="upload-photo">Upload Photos</label>
              </div>
              <div class="col-md-6 col-lg-4">
                <input
                  type="file"
                  name="upload-video"
                  value=""
                  onChange={courseForm}
                />
                <label htmlFor="upload-video">Upload Videos</label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <h3>Key Details</h3>
              </div>
              <div class="col-lg-4 col-md-6">
                <select name="">
                  <option selected>Choose Instructor</option>
                  <option>Kenneth Gains</option>
                  <option>Kenneth Gains</option>
                </select>
              </div>
              <div class="col-lg-4 col-md-6">
                <DateTimePicker
                  amPmAriaLabel="Select AM/PM"
                  calendarAriaLabel="Toggle calendar"
                  clearAriaLabel="Clear value"
                  dayAriaLabel="Day"
                  hourAriaLabel="Hour"
                  maxDetail="second"
                  minuteAriaLabel="Minute"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date and time"
                  onChange={setCourseDetail}
                  secondAriaLabel="Second"
                  value={courseDetail.startDate}
                  yearAriaLabel="Year"
                />
                {/* <input
                  type="text"
                  placeholder="Start Date"
                  name="startDate"
                  value={courseDetail.startDate}
                  onChange={courseForm}
                /> */}
              </div>
              <div class="col-lg-4 col-md-6">
                <input
                  type="text"
                  placeholder="End Date"
                  name="endDate"
                  value={courseDetail.endDate}
                  onChange={courseForm}
                />
              </div>
              <div class="col-lg-4 col-md-6">
                <input
                  type="text"
                  placeholder="Duration"
                  name="duration"
                  value={courseDetail.duration}
                  onChange={courseForm}
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <h3>Course Details</h3>
              </div>
              <div className="col-12">
                <textarea
                  rows="8"
                  name="description"
                  value={courseDetail.description}
                  onChange={courseForm}
                ></textarea>
              </div>
            </div>
            <button class="theme-button mt-5" onClick={(e) => handleSubmit(e)}>
              {isEdit ? "Update" : "Create"} Course
              {isBtnLoading ? (
                <>
                  <i className="cstm-loader"></i>
                </>
              ) : (
                ""
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateCourse;
