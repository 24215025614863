import React from "react";
import { Link } from "react-router-dom";

function openSideBar() {
  document.getElementsByTagName("body")[0].classList.toggle("side-bar-open");
}

export const openRightSideBar = () => {
  let element = document.getElementsByClassName("right-side-bar");
  for (var i = 0; i < element.length; i++) {
    element.item(i).classList.toggle("open");
  }
  document.body.classList.add("overlay");
};

export function closeRightSideBar() {
  let element = document.getElementsByClassName("right-side-bar");
  for (var i = 0; i < element.length; i++) {
    element.item(i).classList.remove("open");
  }
  document.body.classList.remove("overlay");
}

export function SideBarIcon() {
  return (
    <>
      <img
        src="/images/icons/sidebar_icon11.svg"
        alt="sidebar"
        className="icon"
        onClick={openSideBar}
      />
    </>
  );
}

export function AddIcon(props) {
  return (
    <>
      <img
        src="/images/icons/add_icon_grey.svg"
        alt="add-icon"
        className="icon"
      />
    </>
  );
}

export function BackCloseRightBarIcon() {
  return (
    <>
      <img
        src="/images/icons/back_icon_grey.svg"
        alt="back-icon"
        className="icon"
        onClick={closeRightSideBar}
      />
    </>
  );
}

export function BackIcon(props) {
  return (
    <>
      <Link to={props.path}>
        <img
          src="/images/icons/back_icon_grey.svg"
          alt="back-icon"
          className="icon"
        />
      </Link>
    </>
  );
}
