import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AddIcon, SideBarIcon } from "../../../components/TopHeader/TopHeader";
import { API_LIST_RANGE } from "../../../config/Endpoints";
import { ShowToast } from "../../../helpers/Helpers";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";
import CreateRange from "./CreateRange";
import RangeTable from "./RangeTable";
import RangeTabs from "./RangeTabs";

const Ranges = () => {
  const [rangeData, setRangeData] = useState([]);
  const [rangeForm, setRangeDetail] = useState(
    "right-side-bar g-0 overflow-auto"
  );

  const openRangeForm = () => {
    setRangeDetail((prevClass) => prevClass + " open");
    document.body.classList.add("overlay");
  };

  useEffect(() => {
    fetchRanges();
  }, []);

  const fetchRanges = () => {
    fetch(API_LIST_RANGE + "", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          if (errorList) {
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
          } else {
            ShowToast("error", "Something went wrong!");
          }
        } else {
          setRangeData(data.data);
        }
      });
  };

  const handleReloadData = () => {
    console.log("From Comp");
    fetchRanges();
  };

  return (
    <>
      <div className="wrapper d-flex">
        <SidebarMenu />
        <div className="wrapper-body">
          <div className="top-header pe-4">
            <SideBarIcon />
            <Link onClick={openRangeForm}>
              <AddIcon />
            </Link>
            <h3>Range Management</h3>
          </div>
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-12">
                {/* 
                <div class="large-icon-buttons mt-5">
                  <RangeTabs
                    path={"/manage-ranges"}
                    imgpath={"./images/icons/manage-inventory.svg"}
                    tabtitle={"Manage Range"}
                  />
                  <RangeTabs
                    path={"/"}
                    imgpath={"./images/icons/rental-inventory.svg"}
                    tabtitle={"Active Range"}
                  />
                  <RangeTabs
                    path={"/"}
                    imgpath={"./images/icons/manage-bound-book.svg"}
                    tabtitle={"Inactive Range"}
                  />
                  <RangeTabs
                    path={"/"}
                    imgpath={"./images/icons/manage-bound-book.svg"}
                    tabtitle={"Need Maintainance"}
                  />
                </div>
                 */}

                <form className="card form-dark px-3">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Search Range By Name."
                        className="mb-0"
                      />
                    </div>
                    <div className="col-md-3">
                      <select className="mb-0">
                        <option selected>Filter by</option>
                        <option>Active Range</option>
                        <option>Inactive Range</option>
                        <option>Need Maintainance</option>
                      </select>
                    </div>
                    <div className="col-md-2 ms-auto">
                      <button type="submit" className="theme-button">
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-12">
                <div className="card">
                  <RangeTable rangeData={rangeData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateRange
        rangeSideBarClass={rangeForm}
        reloadData={handleReloadData}
      />
    </>
  );
};

export default Ranges;
