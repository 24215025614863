import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  AddIcon,
  BackIcon,
  openRightSideBar,
  SideBarIcon,
} from "../../../components/TopHeader/TopHeader";
import { API_VIEW_RANGE } from "../../../config/Endpoints";
import { ShowToast } from "../../../helpers/Helpers";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";
import CreateRange from "./CreateRange";
import { RangeBookingAnnualy, RangeBookingWeekly } from "./RangeCharts";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const RangeDetail = (props) => {
  const { id } = useParams();
  const [rangeForm, setRangeDetail] = useState(
    "right-side-bar g-0 overflow-auto"
  );
  const [rangeDetails, setRangeDetails] = useState({
    address: { formatted_address: "" },
    contactInfo: { phone_number: "" },
  });

  const fetchRange = () => {
    fetch(API_VIEW_RANGE + "?id=" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          if (errorList) {
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
          } else {
            ShowToast("error", "Something went wrong!");
          }
        } else {
          setRangeDetails(data.data);
        }
      });
  };

  useEffect(() => {
    fetchRange();
  }, []);

  return (
    <>
      <div className="wrapper d-flex">
        <SidebarMenu />
        <div className="wrapper-body">
          <div className="top-header pe-4">
            <SideBarIcon />
            <BackIcon path={"/manage-ranges"} />
            <h3>Range Detail</h3>
            <div className="actions align-items-center d-flex ms-auto">
              <Link to={"/lanes"} className="theme-text me-4">
                Bay's & Lanes
              </Link>
            </div>
          </div>
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-md-4 border-end border-secondary">
                    <div className="about-range position-sticky">
                      <div className="d-flex">
                        <img
                          src="https://shotpro.com/wp-content/uploads/2022/05/Screenshot-2022-05-19-160347-300x121.png"
                          alt="Logo"
                          className="img-fluid mb-3"
                          width="170"
                        />
                        <Link
                          className="ms-auto p-2 theme-text small"
                          onClick={openRightSideBar}
                        >
                          <img src="/images/icons/edit_theme.svg" alt="Edit" />
                          &nbsp;&nbsp;Edit Range
                        </Link>
                      </div>
                      <h4>{rangeDetails.name}</h4>
                      <p>
                        <span className="bg-info bg-opacity-50 p-1 px-3 rounded text-white small">
                          Available
                        </span>
                      </p>
                      <hr />
                      <h3 className="theme-text-blue-grey">Sub Heading</h3>
                      <p>{rangeDetails.headline}</p>
                      <h3 className="theme-text-blue-grey">Booking</h3>
                      <p>Require Approval</p>
                      <h3 className="theme-text-blue-grey">Total Lanes</h3>
                      <p>{rangeDetails.lanes}</p>
                      <h3 className="theme-text-blue-grey">
                        Bookings Allowed Uptill
                      </h3>
                      <p>Nov 20, 2022</p>
                      <h3 className="theme-text-blue-grey">Contact</h3>
                      <p>{rangeDetails.contactInfo.phone_number}</p>
                      <hr />
                      <h3 className="theme-text-blue-grey mb-3">
                        Hours of operation
                      </h3>
                      <div className="hours">
                        <p>
                          <span>Sunday : </span>{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[0]
                            ? rangeDetails.operationHours[0].hourOpen
                            : ""}{" "}
                          -{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[0]
                            ? rangeDetails.operationHours[0].hourClose
                            : ""}
                        </p>
                        <p>
                          <span>Monday : </span>{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[1]
                            ? rangeDetails.operationHours[1].hourOpen
                            : ""}{" "}
                          -{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[1]
                            ? rangeDetails.operationHours[1].hourClose
                            : ""}
                        </p>
                        <p>
                          <span>Tuesday : </span>
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[2]
                            ? rangeDetails.operationHours[2].hourOpen
                            : ""}{" "}
                          -{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[2]
                            ? rangeDetails.operationHours[2].hourClose
                            : ""}
                        </p>
                        <p>
                          <span>Wednesday : </span>{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[3]
                            ? rangeDetails.operationHours[3].hourOpen
                            : ""}{" "}
                          -{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[3]
                            ? rangeDetails.operationHours[3].hourClose
                            : ""}
                        </p>
                        <p>
                          <span>Thursday : </span>{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[4]
                            ? rangeDetails.operationHours[4].hourOpen
                            : ""}{" "}
                          -{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[4]
                            ? rangeDetails.operationHours[4].hourClose
                            : ""}
                        </p>
                        <p>
                          <span>Friday : </span>{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[5]
                            ? rangeDetails.operationHours[5].hourOpen
                            : ""}{" "}
                          -{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[5]
                            ? rangeDetails.operationHours[5].hourClose
                            : ""}
                        </p>
                        <p>
                          <span>Saturday : </span>{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[6]
                            ? rangeDetails.operationHours[6].hourOpen
                            : ""}{" "}
                          -{" "}
                          {rangeDetails.operationHours &&
                          rangeDetails.operationHours[6]
                            ? rangeDetails.operationHours[6].hourClose
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h3 className="theme-text-blue-grey">Upcoming Bookings</h3>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Lane</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Start</th>
                          <th>End</th>
                          <th>Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Kenneth Gains</td>
                          <td>2</td>
                          <td>Oct 20</td>
                          <td>Oct 23</td>
                          <td>09:30 am</td>
                          <td>11:45 am</td>
                          <td>Online</td>
                        </tr>
                        <tr>
                          <td>Tim Peterson</td>
                          <td>3</td>
                          <td>Oct 21</td>
                          <td>Oct 22</td>
                          <td>12:30 am</td>
                          <td>01:45 am</td>
                          <td>Cash</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <h3 className="theme-text-blue-grey">Description</h3>
                    <p>{rangeDetails.description}</p>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <RangeBookingWeekly />
                      </div>
                      <div className="col-md-6">
                        <RangeBookingAnnualy />
                      </div>
                    </div>

                    <hr />
                    <h3 className="theme-text-blue-grey">Location</h3>
                    <p>
                      <p>{rangeDetails.address.formatted_address}</p>
                    </p>
                    {/* <iframe
                      title="Google Map"
                      width="100%"
                      height="300"
                      src={
                        "https://maps.google.com/maps?width=100%&amp;height=300&amp;hl=en&amp;q=Atlanta%20Midtown+(Stoddard%5C's%20Range%20and%20Guns)&amp;ie=UTF8&amp;t=&amp;z=18&amp;iwloc=B&amp;output=embed"
                      }
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                    ></iframe> */}
                    <LoadScript
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    >
                      <GoogleMap
                        mapContainerStyle={{
                          width: "100%",
                          height: "400px",
                        }}
                        center={{
                          lat: rangeDetails.address.lat,
                          lng: rangeDetails.address.long,
                        }}
                        zoom={10}
                      >
                        {/* Child components, such as markers, info windows, etc. */}
                        <>
                          <Marker
                            position={{
                              lat: rangeDetails.address.lat,
                              lng: rangeDetails.address.long,
                            }}
                          />
                          <InfoWindow
                            position={{
                              lat: rangeDetails.address.lat,
                              lng: rangeDetails.address.long,
                            }}
                          >
                            <div style={{ color: "#000" }}>
                              <h6>{rangeDetails.address.formatted_address}</h6>
                            </div>
                          </InfoWindow>
                        </>
                      </GoogleMap>
                    </LoadScript>

                    <hr />
                    <h3 className="theme-text-blue-grey mb-3">Gallery</h3>
                    <div className="d-flex flex-wrap gap-3">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/8/8f/Federal_law_enforcement_officers_during_firearms_training_exercises_at_indoor_firing_range.jpg"
                        alt=""
                        className="img-fluid"
                        width="170"
                      />
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/8/8f/Federal_law_enforcement_officers_during_firearms_training_exercises_at_indoor_firing_range.jpg"
                        alt=""
                        className="img-fluid"
                        width="170"
                      />
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/8/8f/Federal_law_enforcement_officers_during_firearms_training_exercises_at_indoor_firing_range.jpg"
                        alt=""
                        className="img-fluid"
                        width="170"
                      />
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/8/8f/Federal_law_enforcement_officers_during_firearms_training_exercises_at_indoor_firing_range.jpg"
                        alt=""
                        className="img-fluid"
                        width="170"
                      />
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/8/8f/Federal_law_enforcement_officers_during_firearms_training_exercises_at_indoor_firing_range.jpg"
                        alt=""
                        className="img-fluid"
                        width="170"
                      />
                    </div>
                    <hr />
                    <h3 className="theme-text-blue-grey">Policy</h3>
                    <p>{rangeDetails.policy}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateRange
        rangeSideBarClass={rangeForm}
        updateData={rangeDetails}
        // reloadData={handleReloadData}
      />
    </>
  );
};

export default RangeDetail;
