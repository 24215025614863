import React, {useState} from "react";
import { Link } from "react-router-dom";
import { AddIcon, BackCloseRightBarIcon, BackIcon, SideBarIcon } from "../../../../components/TopHeader/TopHeader";
import SidebarMenu from "../../../../layouts/SidebarMenu/SidebarMenu";

const Lanes = () => {
    const [createBayForm, setBayForm] = useState('right-side-bar g-0 overflow-auto');
    const [createLaneForm, setLaneForm] = useState('right-side-bar g-0 overflow-auto');

    const openBayForm = () => {
        setBayForm(prevClass => prevClass + ' open');
        document.body.classList.add('overlay')
    };
    const openLaneForm = () => {
        setLaneForm(prevClass => prevClass + ' open');
        document.body.classList.add('overlay')
    };
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <BackIcon path={"/manage-ranges"} />
                        <h3>Bay's & Lanes</h3>
                        <div className="actions align-items-center d-flex ms-auto">
                            <Link className="m-0 me-4 px-4 py-2 theme-button"  onClick={openBayForm}>Create Bay</Link>
                        </div>
                    </div>
                    <div className="container-fluid p-4 lanes">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header d-flex px-3 py-2 border-bottom border-secondary align-items-center">
                                        <h5 class="fs-6 theme-text-blue-grey mb-0">This is bay name</h5>
                                        <Link className="ms-auto me-3" title="Add Lane" onClick={openLaneForm}>
                                            <AddIcon />
                                        </Link>
                                        <Link title="Edit Bay" onClick={openLaneForm}>
                                            <img src="/images/icons/edit_theme.svg" />
                                        </Link>
                                    </div>
                                    <div className="card-body p-3">
                                        <table className="border border-secondary">
                                            <tr>
                                                <td className="bg-gradient">
                                                    No of Lanes
                                                    <h6 className="text-white">5</h6>
                                                </td>
                                                <td className="bg-gradient">
                                                    Lanes Reserved
                                                    <h6 className="text-white">2</h6>
                                                </td>
                                            </tr>
                                        </table>
                                        <div className="lane-diag d-flex">
                                            <div className="item">1</div>
                                            <div className="item reserved">2</div>
                                            <div className="item">3</div>
                                            <div className="item reserved">4</div>
                                            <div className="item">5</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header d-flex px-3 py-2 border-bottom border-secondary align-items-center">
                                        <h5 class="fs-6 theme-text-blue-grey mb-0">This is bay name</h5>
                                        <Link className="ms-auto me-3" title="Add Lane">
                                            <AddIcon />
                                        </Link>
                                        <Link title="Edit Bay">
                                            <img src="/images/icons/edit_theme.svg" />
                                        </Link>
                                    </div>
                                    <div className="card-body p-3">
                                        <table className="border border-secondary">
                                            <tr>
                                                <td className="bg-gradient">
                                                    No of Lanes
                                                    <h6 className="text-white">3</h6>
                                                </td>
                                                <td className="bg-gradient">
                                                    Lanes Reserved
                                                    <h6 className="text-white">1</h6>
                                                </td>
                                            </tr>
                                        </table>
                                        <div className="lane-diag d-flex">
                                            <div className="item">1</div>
                                            <div className="item reserved">2</div>
                                            <div className="item">3</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header d-flex px-3 py-2 border-bottom border-secondary align-items-center">
                                        <h5 class="fs-6 theme-text-blue-grey mb-0">This is bay name</h5>
                                        <Link className="ms-auto me-3" title="Add Lane">
                                            <AddIcon />
                                        </Link>
                                        <Link title="Edit Bay">
                                            <img src="/images/icons/edit_theme.svg" />
                                        </Link>
                                    </div>
                                    <div className="card-body p-3">
                                        <table className="border border-secondary">
                                            <tr>
                                                <td className="bg-gradient">
                                                    No of Lanes
                                                    <h6 className="text-white">1</h6>
                                                </td>
                                                <td className="bg-gradient">
                                                    Lanes Reserved
                                                    <h6 className="text-white">0</h6>
                                                </td>
                                            </tr>
                                        </table>
                                        <div className="lane-diag d-flex">
                                            <div className="item">1</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header d-flex px-3 py-2 border-bottom border-secondary align-items-center">
                                        <h5 class="fs-6 theme-text-blue-grey mb-0">This is bay name</h5>
                                        <Link className="ms-auto me-3" title="Add Lane">
                                            <AddIcon />
                                        </Link>
                                        <Link title="Edit Bay">
                                            <img src="/images/icons/edit_theme.svg" />
                                        </Link>
                                    </div>
                                    <div className="card-body p-3">
                                        <table className="border border-secondary">
                                            <tr>
                                                <td className="bg-gradient">
                                                    No of Lanes
                                                    <h6 className="text-white">3</h6>
                                                </td>
                                                <td className="bg-gradient">
                                                    Lanes Reserved
                                                    <h6 className="text-white">0</h6>
                                                </td>
                                            </tr>
                                        </table>
                                        <div className="lane-diag d-flex">
                                            <div className="item">1</div>
                                            <div className="item">2</div>
                                            <div className="item">3</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={createBayForm}>
                        <header>
                            <BackCloseRightBarIcon />
                            <h3 className="theme-text-white main-tab-heading-sun mb-0 ps-4">Create Bay</h3>
                        </header>
                        <div className="body p-5">
                            <form class="form-dark">
                                <div class="row">
                                    <div class="col-12">
                                        <h3>Bay Detail</h3>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" placeholder="Bay Name" />
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" placeholder="No. Of Lanes" />
                                    </div>
                                </div>
                                <button class="theme-button mt-5">Create Bay</button>
                            </form>
                        </div>
                    </div>

                    <div className={createLaneForm}>
                        <header>
                            <BackCloseRightBarIcon />
                            <h3 className="theme-text-white main-tab-heading-sun mb-0 ps-4">Create Lane</h3>
                        </header>
                        <div className="body p-5">
                            <form class="form-dark">
                                <div class="row">
                                    <div class="col-12">
                                        <h3>Lane Detail</h3>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="number" placeholder="Lane Number" />
                                    </div>
                                    <div class="col-md-4">
                                        <select>
                                            <option selected>Lane Status</option>
                                            <option>Active</option>
                                            <option>Inactive</option>
                                            <option>Need Maintainance</option>
                                        </select>
                                    </div>
                                </div>
                                <button class="theme-button mt-5">Create Lane</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default Lanes;