import React from 'react';
import { Link } from 'react-router-dom';
import { AddIcon, SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const Categories = () => {


    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <AddIcon />
                        <h3>Product Categories</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <div class="categories">
                                    <div className="item">
                                        <Link to={"/products"}>
                                            <h3 className='theme-heading-main'>Front Page Hero Slider</h3>
                                            <div className="data d-flex flex-wrap gap-4">
                                                <p><span>Total Products</span>46</p>
                                                <p><span>Products Live</span>3</p>
                                                <p><span>Out of order</span>2</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item">
                                        <Link to={"/products"}>
                                            <h3 className='theme-heading-main'>Front Page Featured</h3>
                                            <div className="data d-flex flex-wrap gap-4">
                                                <p><span>Total Products</span>46</p>
                                                <p><span>Products Live</span>3</p>
                                                <p><span>Out of order</span>2</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Categories;