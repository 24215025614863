import React from "react";
import { Link } from "react-router-dom";

const Tabs = (props) => {
    return (
        <>
            <Link to={props.path}>
                <div class="item">
                    <div class="icon">
                        <img src={props.imgpath} alt="icon" />
                    </div>
                    <div class="text">
                        <p class="inventory main-tab-semi-heading">
                            {props.tabtitle}
                        </p>
                    </div>
                </div>
            </Link>
        </>
    )
};

export default Tabs;