import React from "react";
import { BackIcon, SideBarIcon } from "../../../components/TopHeader/TopHeader";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";
import { ProductSaleAnnualy, ProductSaleWeekly } from "./ProductCharts";

const ProductDetail = () => {
    return(
        <>
        <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <BackIcon path={'/products'} />
                        <h3>Prroduct Detail</h3>
                    </div>
                    <div className="events">
                        <div className="container-fluid p-4">
                            <div className="row">
                                <div className="col-md-8">
                                    <h2 className="events__title">The ShotPro Hat</h2>
                                    <p className="theme-text-blue-grey">Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni adipisci maiores odio sequi, possimus asperiores provident a error animi saepe, culpa, alias consectetur quam. Ipsa maxime architecto obcaecati nesciunt repellat.</p>
                                </div>
                                <div className="col-md-4">
                                    <p><span className="bg-info bg-opacity-50 p-1 px-3 rounded text-white small">Front Page Hero Slider</span></p>
                                    <div className="events__price-tab">
                                        <p>
                                            <span>Price</span>
                                            $99
                                        </p>
                                        <p>
                                            <span>Sale Price</span>
                                            $49
                                        </p>
                                        <p>
                                            <span>Discount</span>
                                            52%
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                                <div className="col-md-8 events__border-right">
                                    <h3 className="fs-6 theme-text-blue-grey d-flex">Product Images</h3>
                                    <div className="events__gallery">
                                        <img src="./images/full-shot-pro-1.png" alt="Home Firearm Safety Course" />
                                        <img src="./images/full-shot-pro-1.png" alt="Home Firearm Safety Course" />
                                        <img src="./images/full-shot-pro-1.png" alt="Home Firearm Safety Course" />
                                    </div>
                                    <hr />
                                    <h3 className="fs-6 theme-text-blue-grey d-flex">Analytics</h3>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <ProductSaleWeekly />
                                    </div>
                                    <div className="col-md-6">
                                        <ProductSaleAnnualy />
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <h3 className="fs-6 theme-text-blue-grey d-flex">Key Highlights </h3>
                                    <table>
                                        <tbody>

                                            <tr>
                                                <td>Size</td>
                                                <td>Normal</td>
                                            </tr>
                                            <tr>
                                                <td>Color</td>
                                                <td>Black</td>
                                            </tr>
                                            <tr>
                                                <td>Quantity</td>
                                                <td>423</td>
                                            </tr>
                                            <tr>
                                                <td>Shipping Cost</td>
                                                <td>$5</td>
                                            </tr>
                                            <tr>
                                                <td>Created By</td>
                                                <td>John Doe</td>
                                            </tr>
                                            <tr>
                                                <td>Added On</td>
                                                <td>Nov 25, 2022</td>
                                            </tr>
                                        </tbody>
                                    </table>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDetail;