import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AddIcon,
  BackIcon,
  SideBarIcon,
} from "../../../components/TopHeader/TopHeader";
import { API_LIST_RANGE } from "../../../config/Endpoints";
import { ShowToast } from "../../../helpers/Helpers";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";
import CreateRange from "./CreateRange";
import RangeTable from "./RangeTable";

const ManageRanges = () => {
  const [rangeForm, setRangeDetail] = useState(
    "right-side-bar g-0 overflow-auto"
  );
  const openRangeForm = () => {
    setRangeDetail((prevClass) => prevClass + " open");
    document.body.classList.add("overlay");
  };

  const [rangeData, setRangeData] = useState([]);

  useEffect(() => {
    fetchRanges();
  }, []);

  const fetchRanges = () => {
    fetch(API_LIST_RANGE + "?per_page=100", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          if (errorList) {
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
          } else {
            ShowToast("error", "Something went wrong!");
          }
        } else {
          setRangeData(data.data);
        }
      });
  };

  const handleReloadData = () => {
    console.log("From Comp");
    fetchRanges();
  };

  return (
    <>
      <div className="wrapper d-flex">
        <SidebarMenu />
        <div className="wrapper-body">
          <div className="top-header pe-4">
            <SideBarIcon />
            <BackIcon path={"/ranges"} />
            <Link onClick={openRangeForm}>
              <AddIcon />
            </Link>
            <h3>Manage Ranges</h3>
          </div>
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-12">
                <RangeTable rangeData={rangeData} />
              </div>
            </div>
          </div>
          <CreateRange
            rangeSideBarClass={rangeForm}
            reloadData={handleReloadData}
          />
        </div>
      </div>
    </>
  );
};

export default ManageRanges;
