import React from "react";
import { Link } from "react-router-dom";

const CoursesListItems = (props) => {
    return (
        <>
            <div className="courses__list-item">
                <Link to={props.path}>
                    <img src={props.image} alt="course banner" className="w-100" height={100} />
                    <div className="p-3">
                        <h3>{props.title}</h3>
                        <p>
                            <span>{props.views} Viewers</span>
                            <span>{props.lessons}</span>
                            <span>{props.duration} Hours</span>
                        </p>
                    </div>
                </Link>
            </div>
        </>
    )
};

export default CoursesListItems;