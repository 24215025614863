import { toast } from "react-toastify";
import { createBrowserHistory } from "history";
import { getLocalDataObject } from "./Storage";

export const ShowToast = (type, title) => {
  let options = {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  if (type === "success") {
    toast.success(title, options);
  }

  if (type === "info") {
    toast.info(title, options);
  }

  if (type === "warning") {
    toast.warning(title, options);
  }

  if (type === "error") {
    toast.error(title, options);
  }
};

export const history = createBrowserHistory({});

export const getCountry = (addrComponents) => {
  for (var i = 0; i < addrComponents.length; i++) {
    if (addrComponents[i].types[0] == "country") {
      return addrComponents[i].short_name;
    }
    if (addrComponents[i].types.length == 2) {
      if (addrComponents[i].types[0] == "political") {
        return addrComponents[i].short_name;
      }
    }
  }
  return false;
};

export const getCity = (addrComponents) => {
  for (var i = 0; i < addrComponents.length; i++) {
    if (addrComponents[i].types[0] == "locality") {
      return addrComponents[i].long_name;
    }
    if (addrComponents[i].types.length == 2) {
      if (addrComponents[i].types[0] == "political") {
        return addrComponents[i].long_name;
      }
    }
  }
  return false;
};

export const getState = (addrComponents) => {
  for (var i = 0; i < addrComponents.length; i++) {
    if (addrComponents[i].types[0] == "administrative_area_level_1") {
      return addrComponents[i].long_name;
    }
    if (addrComponents[i].types.length == 2) {
      if (addrComponents[i].types[0] == "political") {
        return addrComponents[i].long_name;
      }
    }
  }
  return false;
};
