import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { AddIcon, SideBarIcon } from "../../../components/TopHeader/TopHeader";
import { API_LIST_COURSE } from "../../../config/Endpoints";
import { ShowToast } from "../../../helpers/Helpers";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";
import CoursesListItems from "./CoursesListItems";
import CreateCourse from "./CreateCourse";

const Training = () => {
  const [courseForm, setCourseDetail] = useState(
    "right-side-bar g-0 overflow-auto"
  );
  const [courseList, setCourseList] = useState([]);

  const openEmployeeForm = () => {
    setCourseDetail((prevClass) => prevClass + " open");
    document.body.classList.add("overlay");
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    fetch(API_LIST_COURSE + "?status=1&range_id=1&per_page=100", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          let errorList = data.error.fieldErrors;
          if (errorList) {
            errorList.forEach((element) => {
              ShowToast("error", element.message);
            });
          } else {
            ShowToast("error", "Something went wrong!");
          }
        } else {
          setCourseList(data.data);
        }
      });
  };

  const handleReloadData = () => {
    console.log("From Comp");
    fetchCourses();
  };

  return (
    <>
      <div className="wrapper d-flex">
        <SidebarMenu />
        <div className="wrapper-body">
          <div className="top-header pe-4">
            <SideBarIcon />
            <Link onClick={openEmployeeForm}>
              <AddIcon />
            </Link>
            <h3>Training Management</h3>
            <ul>
              <li>
                <Link to={"/training"}>
                  <span className="theme-text">Training</span>
                </Link>
              </li>
              <li className="disabled">
                <Link to={"/instructor"}>Instructors </Link>
              </li>
            </ul>
          </div>
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-12 my-3">
                <h5 className="fs-6 theme-text-blue-grey d-flex mb-3">
                  Courses
                </h5>
                <div className="courses">
                  {courseList.map((course, index) => (
                    <CoursesListItems
                      key={index}
                      path={`/training-detail/${course.id}`}
                      image={"./images/big-durobeen-gun.png"}
                      title={course.name}
                      views={"402"}
                      lessons={"4 Lessons"}
                      duration={"19"}
                    />
                  ))}
                </div>
              </div>
              {/* <div className="col-12 my-3">
                <h5 className="d-flex align-items-center fs-6 theme-text-blue-grey d-flex mb-3">
                  Filter By Instructor
                  <select
                    name="select-instructor"
                    className="input-dark ms-3 mb-0 border"
                    style={{ width: "150px" }}
                  >
                    <option value="1">Select Instructor</option>
                    <option value="2">John Doe</option>
                    <option value="3">Kenneth Gaines</option>
                  </select>
                </h5>
                <p>
                  <small>Results showing Kenneth Gains</small>
                </p>
                <div className="courses">
                  <CoursesListItems
                    path={"/training-detail"}
                    image={"./images/big-durobeen-gun.png"}
                    title={"Home Firearm Safety Course"}
                    views={"402"}
                    lessons={"4 Lessons"}
                    duration={"19"}
                  />
                  <CoursesListItems
                    path={"/training-detail"}
                    image={"./images/courses-big-black-gun.png"}
                    title={"Home Firearm Safety Course"}
                    views={"402"}
                    duration={"19"}
                  />
                  <CoursesListItems
                    path={"/training-detail"}
                    image={"./images/courses-big-gun.png"}
                    title={"Home Firearm Safety Course"}
                    views={"402"}
                    duration={"19"}
                  />
                  <CoursesListItems
                    path={"/training-detail"}
                    image={"./images/courses-big-black-gun.png"}
                    title={"Home Firearm Safety Course"}
                    views={"402"}
                    duration={"19"}
                  />
                  <CoursesListItems
                    path={"/training-detail"}
                    image={"./images/big-man.png"}
                    title={"Home Firearm Safety Course"}
                    views={"402"}
                    duration={"19"}
                  />
                </div>
              </div>
              <div className="col-12 my-3">
                <h5 className="fs-6 theme-text-blue-grey d-flex mb-3">
                  Private Training
                </h5>
                <div className="courses">
                  <CoursesListItems
                    path={"/training-detail"}
                    image={"./images/courses-big-gun.png"}
                    title={"Home Firearm Safety Course"}
                    views={"402"}
                    lessons={"4 Lessons"}
                    duration={"19"}
                  />
                  <CoursesListItems
                    path={"/training-detail"}
                    image={"./images/big-durobeen-gun.png"}
                    title={"Home Firearm Safety Course"}
                    views={"402"}
                    duration={"19"}
                  />
                  <CoursesListItems
                    path={"/training-detail"}
                    image={"./images/courses-big-black-gun.png"}
                    title={"Home Firearm Safety Course"}
                    views={"402"}
                    duration={"19"}
                  />
                </div>
              </div>
              <div className="col-12 my-3">
                <h5 className="fs-6 theme-text-blue-grey d-flex mb-3">
                  Events
                </h5>
                <div className="courses">
                  <CoursesListItems
                    path={"/training-detail"}
                    image={"./images/big-dark-gun.png"}
                    title={"Home Firearm Safety Course"}
                    views={"60"}
                    duration={"2"}
                  />
                </div>
              </div> */}
            </div>
          </div>

          <CreateCourse
            reloadData={handleReloadData}
            courseSideBarClass={courseForm}
          />
        </div>
      </div>
    </>
  );
};

export default Training;
