import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SideBarIcon } from "../../../components/TopHeader/TopHeader";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";
import { EmployeeChart, RevenueChart } from "./Charts";
import { API_USER_PROFILE } from "../../../config/Endpoints";
function closeSideBar() {
  alert("Icon Clicked");
}

const Dashbaord = () => {
  const [profileData, setData] = useState({profile:{firstName: ''}});
  useEffect(() => {
    fetch(API_USER_PROFILE, {
      method: "GET",
    })  
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
        } else {
          setData(data.data);
        }
      });
  }, []); 

  return (
    <>
      <div className="wrapper d-flex">
        <SidebarMenu />
        <div className="wrapper-body">
          <div className="top-header pe-4">
            <SideBarIcon />
            <h3>Dashboard</h3>
            <div className="actions align-items-center d-flex ms-auto">
              <Link to={""} className="theme-text me-4 disabled">Customize Dashboard</Link>
              <Link to={"/profile"}>
              <p className="profile_badge">
                <img src="./images/icons/customer_inactive_icon.svg" alt="profile-icon" />
                {profileData.profile.firstName}
              </p>
              </Link>
            </div>
          </div>
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-lg-8">
                <h3 className="fs-6 theme-text-blue-grey d-flex">
                  Employee Activity
                </h3>
                <div className="card p-3">
                  <EmployeeChart />
                </div>
              </div>

              <div className="col-lg-4">
                <h3 className="fs-6 theme-text-blue-grey d-flex">Earnings</h3>
                <div className="card p-3">
                  <RevenueChart />
                </div>
              </div>

              <div className="col-lg-8 mt-4">
                <h3 className="fs-6 theme-text-blue-grey d-flex">
                  Work Schedule{" "}
                  <a
                    href="../employee-management/employee.html"
                    className="small ms-auto me-3"
                  >
                    <small>View All</small>
                  </a>
                </h3>
                <div
                  className="card overflow-auto"
                  style={{ "max-height": "278px" }}
                >
                  <table>
                    <thead className="position-sticky top-0">
                      <tr>
                        <th>Task Name</th>
                        <th>Assignee</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Due Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          This is a task and it is should be done by today
                          itself.
                        </td>
                        <td>Laurie Peterson</td>
                        <td className="text-center text-warning">
                          In Progress
                        </td>
                        <td className="text-center">Aug 05, 2022</td>
                      </tr>
                      <tr>
                        <td>
                          This is a task and it is should be done by today
                          itself.
                        </td>
                        <td>Laurie Peterson</td>
                        <td className="text-center text-warning">
                          In Progress
                        </td>
                        <td className="text-center">Aug 05, 2022</td>
                      </tr>
                      <tr>
                        <td>
                          This is a task and it is should be done by today
                          itself.
                        </td>
                        <td>Laurie Peterson</td>
                        <td className="text-center text-info">Completed</td>
                        <td className="text-center">Aug 05, 2022</td>
                      </tr>
                      <tr>
                        <td>
                          This is a task and it is should be done by today
                          itself.
                        </td>
                        <td>Laurie Peterson</td>
                        <td className="text-center text-warning">
                          In Progress
                        </td>
                        <td className="text-center">Aug 05, 2022</td>
                      </tr>
                      <tr>
                        <td>
                          This is a task and it is should be done by today
                          itself.
                        </td>
                        <td>Laurie Peterson</td>
                        <td className="text-center text-info">Completed</td>
                        <td className="text-center"> Aug 05, 2022</td>
                      </tr>
                      <tr>
                        <td>
                          This is a task and it is should be done by today
                          itself.
                        </td>
                        <td>Laurie Peterson</td>
                        <td className="text-center text-warning">Overdue</td>
                        <td className="text-center">
                          <label className="bg-danger bg-opacity-50 p-1 px-3 rounded">
                            Aug 05, 2022
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          This is a task and it is should be done by today
                          itself.
                        </td>
                        <td>Laurie Peterson</td>
                        <td className="text-center text-warning">
                          In Progress
                        </td>
                        <td className="text-center">Aug 05, 2022</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-lg-4 mt-4">
                <h3 className="fs-6 theme-text-blue-grey d-flex">
                  Employees Activity{" "}
                  <a
                    href="../employee-management/employee.html"
                    className="small ms-auto me-3"
                  >
                    <small>View All</small>
                  </a>
                </h3>
                <div
                  className="card overflow-auto"
                  style={{ "max-height": "278px" }}
                >
                  <table>
                    <thead className="position-sticky top-0">
                      <tr>
                        <th>Employee</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a href="#!">Norman Smith</a>
                        </td>
                        <td>
                          <span className="activity online"></span>Online
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#!">Norman Smith</a>
                        </td>
                        <td>
                          <span className="activity online"></span>Online
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#!">Norman Smith</a>
                        </td>
                        <td>
                          <span className="activity offline"></span>Offline
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#!">Norman Smith</a>
                        </td>
                        <td>
                          <span className="activity online"></span>Online
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#!">Norman Smith</a>
                        </td>
                        <td>
                          <span className="activity online"></span>Online
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#!">Norman Smith</a>
                        </td>
                        <td>
                          <span className="activity online"></span>Online
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#!">Norman Smith</a>
                        </td>
                        <td>
                          <span className="activity online"></span>Online
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#!">Norman Smith</a>
                        </td>
                        <td>
                          <span className="activity online"></span>Online
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-lg-8 mt-4">
                <h3 className="fs-6 theme-text-blue-grey d-flex">
                  Top Selling Products{" "}
                  <a
                    href="../product-management/shot-product.html"
                    className="small ms-auto me-3"
                  >
                    <small>View All</small>
                  </a>
                </h3>
                <div className="card">
                  <table>
                    <thead className="position-sticky top-0">
                      <tr>
                        <th width="80"></th>
                        <th>Product Name</th>
                        <th className="text-center">Sale</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Qty. Sold</th>
                        <th className="text-center">Qty. Left</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="./images/gun-1.jpg"
                            alt="Pistol"
                            className="img-fluid rounded"
                          />
                        </td>
                        <td>
                          <a href="#!">Springfield Hellcat Pro OSP</a>
                        </td>
                        <td align="center" className="text-info">
                          <b> &uarr; 37%</b>
                        </td>
                        <td align="center">$244.00</td>
                        <td align="center">823</td>
                        <td align="center">27</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./images/gun-2.png"
                            alt="Pistol"
                            className="img-fluid rounded"
                          />
                        </td>
                        <td>
                          <a href="#!">Glock 19 Gen5 9mm 4.02” Barrel 15+1</a>
                        </td>
                        <td align="center" className="text-warning">
                          <b> &darr; 20%</b>
                        </td>
                        <td align="center">$539.00</td>
                        <td align="center">248</td>
                        <td align="center">109</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./images/gun-1.jpg"
                            alt="Pistol"
                            className="img-fluid rounded"
                          />
                        </td>
                        <td>
                          <a href="#!">Springfield Hellcat Pro OSP</a>
                        </td>
                        <td align="center" className="text-info">
                          <b> &uarr; 57%</b>
                        </td>
                        <td align="center">$244.00</td>
                        <td align="center">823</td>
                        <td align="center">27</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-lg-4 mt-4">
                <h3 className="fs-6 theme-text-blue-grey d-flex">
                  Products Running Out{" "}
                  <a
                    href="../product-management/shot-product.html"
                    className="small ms-auto me-3"
                  >
                    <small>View All</small>
                  </a>
                </h3>
                <div className="card">
                  <table>
                    <thead>
                      <tr>
                        <th width="80"></th>
                        <th>Product</th>
                        <th>Qty.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="./images/gun-1.jpg"
                            alt="Pistol"
                            className="img-fluid rounded"
                          />
                        </td>
                        <td>
                          <a href="#!">Springfield Hellcat Pro OSP</a>
                        </td>
                        <td align="center">2</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./images/gun-1.jpg"
                            alt="Pistol"
                            className="img-fluid rounded"
                          />
                        </td>
                        <td>
                          <a href="#!">Springfield Hellcat Pro OSP</a>
                        </td>
                        <td align="center" className="text-danger">
                          0
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./images/gun-1.jpg"
                            alt="Pistol"
                            className="img-fluid rounded"
                          />
                        </td>
                        <td>
                          <a href="#!">Springfield Hellcat Pro OSP</a>
                        </td>
                        <td align="center" className="text-danger">
                          0
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="right-side-bar g-0 overflow-auto">
              <header>
                <img
                  src="./images/icons/back_icon_grey.svg"
                  alt="back_icon_grey"
                  onClick={closeSideBar}
                />
                <h4 className="theme-text-white main-tab-heading-sun mb-0 ps-4">
                  Customize Dashbaord
                </h4>
              </header>
              <div className="dashboard-customize p-5">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="theme-text-blue-grey mb-3">
                      Manage Modules
                    </h3>
                    <div className="list">
                      <div className="checkmark">
                        <label for="today-tasks">Today's Tasks</label>
                        <input type="checkbox" name="today-tasks" />
                      </div>
                      <div className="checkmark">
                        <label for="time-off">Time Off Request</label>
                        <input type="checkbox" name="time-off" />
                      </div>
                      <div className="checkmark">
                        <label for="employee-status">Employee's Status</label>
                        <input type="checkbox" name="employee-status" />
                      </div>
                      <div className="checkmark">
                        <label for="top-selling-products">
                          Top Selling Products
                        </label>
                        <input type="checkbox" name="top-selling-products" />
                      </div>
                      <div className="checkmark">
                        <label for="available-range">Active Ranges</label>
                        <input type="checkbox" name="available-range" />
                      </div>
                      <div className="checkmark">
                        <label for="range-maintainance">
                          Ranges Maintainance
                        </label>
                        <input type="checkbox" name="range-maintainance" />
                      </div>
                      <div className="checkmark">
                        <label for="inactive-ranges">Inactivve Ranges</label>
                        <input type="checkbox" name="inactive-ranges" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h3 className="theme-text-blue-grey mb-3">
                      Graphical View
                    </h3>
                    <div className="list">
                      <div className="checkmark">
                        <label for="employee-activity">
                          Employees Activity
                        </label>
                        <input type="checkbox" name="employee-activity" />
                      </div>
                      <div className="checkmark">
                        <label for="total-revenue">Total Revenue</label>
                        <input type="checkbox" name="total-revenue" />
                      </div>
                      <div className="checkmark">
                        <label for="ranges">Ranges</label>
                        <input type="checkbox" name="ranges" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashbaord;
