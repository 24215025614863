export function setUserData(data) {
  localStorage.setItem("spIsLoggedIn", true);
  localStorage.setItem("spUser", JSON.stringify(data.data));
  localStorage.setItem("spUserProfile", JSON.stringify(data.data.profile));
  localStorage.setItem("spToken", data.data.token.accessToken);
  //session expiry
  var minutesToAdd = 2880; // 48 hours
  var currentDate = new Date();
  var futureDate =
    new Date(currentDate.getTime() + minutesToAdd * 60000).getTime() / 1000;
  localStorage.setItem("spLoginSession", futureDate);
}

export function getUserData() {
  let user = {
    isLoggedIn: localStorage.getItem("spIsLoggedIn"),
    user: JSON.parse(localStorage.getItem("spUser")),
    userProfile: JSON.parse(localStorage.getItem("spUserProfile")),
    token: localStorage.getItem("spToken"),
  };
  return user;
}

export function logoutUser() {
  localStorage.removeItem("spIsLoggedIn");
  localStorage.removeItem("spUser");
  localStorage.removeItem("spUserProfile");
  localStorage.removeItem("spToken");
  localStorage.removeItem("spLoginSession");
  localStorage.removeItem("defaultArtist");
  localStorage.removeItem("sp_redirect_login");
}

export function setLocalDataObject(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
  window.dispatchEvent(new Event("storage"));
}

export function getLocalDataObject(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function deleteLocalDataObject(key) {
  localStorage.removeItem(key);
}

export function setLocalData(key, data) {
  localStorage.setItem(key, data);
}

export function getLocalData(key) {
  return localStorage.getItem(key);
}
