import React from "react";
import { Link } from "react-router-dom";
import { AddIcon, SideBarIcon } from "../../../components/TopHeader/TopHeader";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";

const Instructors = () =>{
    return(
        <><div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <AddIcon />
                        <h3>Instructors</h3>
                        <ul>
                            <li><Link to={'/training'}>Training</Link></li>
                            <li><Link to={'/instructor'}><span className="theme-text">Instructors</span></Link></li>
                        </ul>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                            <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Designation</th>
                                            <th className="text-center">Experience</th>
                                            <th className="text-center">Courses</th>
                                            <th>Description</th>
                                            <th>Joining</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Tim Peterson</td>
                                            <td>Head Of Riffle Department</td>
                                            <td align="center">3</td>
                                            <td align="center"><Link to={''}>2</Link></td>
                                            <td>This is description for notification and it is should be done by today itself.</td>
                                            <td align='center'>Sep 02, 2021</td>
                                            <td className="theme-text text-center">Edit</td>
                                        </tr>
                                        <tr>
                                            <td>Tim Peterson</td>
                                            <td>Head Of Riffle Department</td>
                                            <td align="center">3</td>
                                            <td align="center"><Link to={''}>2</Link></td>
                                            <td>This is description for notification and it is should be done by today itself.</td>
                                            <td align='center'>Sep 02, 2021</td>
                                            <td className="theme-text text-center">Edit</td>
                                        </tr>
                                        <tr>
                                            <td>Tim Peterson</td>
                                            <td>Head Of Riffle Department</td>
                                            <td align="center">3</td>
                                            <td align="center"><Link to={''}>2</Link></td>
                                            <td>This is description for notification and it is should be done by today itself.</td>
                                            <td align='center'>Sep 02, 2021</td>
                                            <td className="theme-text text-center">Edit</td>
                                        </tr>
                                        <tr>
                                            <td>Tim Peterson</td>
                                            <td>Head Of Riffle Department</td>
                                            <td align="center">3</td>
                                            <td align="center"><Link to={''}>2</Link></td>
                                            <td>This is description for notification and it is should be done by today itself.</td>
                                            <td align='center'>Sep 02, 2021</td>
                                            <td className="theme-text text-center">Edit</td>
                                        </tr>
                                        <tr>
                                            <td>Tim Peterson</td>
                                            <td>Head Of Riffle Department</td>
                                            <td align="center">3</td>
                                            <td align="center"><Link to={''}>2</Link></td>
                                            <td>This is description for notification and it is should be done by today itself.</td>
                                            <td align='center'>Sep 02, 2021</td>
                                            <td className="theme-text text-center">Edit</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Instructors;