import React from "react";
import { Link } from "react-router-dom";

const RangeTable = (props) => {
  const rangeData = props.rangeData;
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th width="100"></th>
            <th>Range Name</th>
            <th>Location</th>
            <th>Status</th>
            <th className="text-center">Lanes</th>
            <th className="text-center">In Active</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {rangeData.map((range, index) => (
            <tr key={index}>
              <td>
                <Link to={`/range-detail/${range.id}`}>
                  <img
                    src="https://shotpro.com/wp-content/uploads/2022/05/Screenshot-2022-05-19-160347-300x121.png"
                    alt="Logo"
                    className="img-fluid"
                  />
                </Link>
              </td>
              <td>{range.name}</td>
              <td>
                {range.address.city}, {range.address.state}
              </td>
              <td>
                <span className="bg-info bg-opacity-50 p-1 px-3 rounded">
                  Available
                </span>
              </td>
              <td align="center">{range.lanes}</td>
              <td className="text-center cstm-checkbox">
                <input type="checkbox" />
              </td>
              <td className="theme-text text-center">
                <Link to={`/range-detail/${range.id}`}>View</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default RangeTable;
