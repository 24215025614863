import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
};

const week = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thr',
    'Fri',
    'Sat'
  ];

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const rangeWeekData = {
    labels: week,
    datasets: [
        {
            label: 'Bookings This Week',
            backgroundColor: '#eb7c45',
            borderColor: '#eb7c45',
            data: [22, 24, 32, 30, 36, 30, 34],
        }
    ],
};

export const rangeMonthsData = {
    labels: months,
    datasets: [
        {
            label: 'Bookings This Year',
            backgroundColor: '#eb7c45',
            borderColor: '#eb7c45',
            data: [283, 339, 327, 193, 394, 756, 283],
        }
    ],
};

export function RangeBookingWeekly() {
    return <Line options={options} data={rangeWeekData} />;
}


export function RangeBookingAnnualy() {
    return <Line options={options} data={rangeMonthsData} />;
}
