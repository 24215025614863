import React from "react";
import { Link } from "react-router-dom";
import { BackIcon, SideBarIcon } from "../../../components/TopHeader/TopHeader";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";

const OrderDetail = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <BackIcon path={'/orders'} />
                        <h3>Orders Detail</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <h2 class="events__title">Order #42007 details</h2>
                                        <p class="theme-text-blue-grey">Payment via card (ch_3MbOBlE8Eey3WouU0MIJ6Hs4). Paid on February 14, 2023 @ 7:04 am. Customer IP: 182.68.182.174</p>
                                    </div>
                                    <div className="col-lg-3">
                                        <button type="button" className="theme-disabled-button">Refund</button>
                                        <button type="button" className="theme-button ms-3">Edit Order</button>
                                    </div>
                                </div>
                                
                                <hr />
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card">
                                            <h3 class="fs-6 theme-text-blue-grey d-flex p-2 bg-dark">General</h3>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Date Created</td>
                                                        <td>2023-01-14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Time</td>
                                                        <td>07:30 pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status</td>
                                                        <td>Processing</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Customer</td>
                                                        <td>Saurabh Kumar</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Active Membership</td>
                                                        <td>Stodard's Midtown Indvidual</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="card">
                                            <h3 class="fs-6 theme-text-blue-grey d-flex p-2 bg-dark">Billing</h3>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Address</td>
                                                        <td>No billing address set.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email</td>
                                                        <td>saurabh.kumar@shotpro.com</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="card mt-3">
                                            <h3 class="fs-6 theme-text-blue-grey d-flex p-2 bg-dark">Shipping</h3>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Address</td>
                                                        <td>No shipping address set.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mt-3">
                                            <h3 class="fs-6 theme-text-blue-grey d-flex p-2 bg-dark">Customer Detail</h3>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>Saurabh Kumar</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Previous Orders</td>
                                                        <td><Link className="text-decoration-underline">4</Link></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Memberr Since</td>
                                                        <td>Jan, 2022</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Membership</td>
                                                        <td>Stodard's Midtown Indvidual</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            <Link className="theme-text">Visit Profile</Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12">
                                    <h3 class="fs-6 theme-text-blue-grey d-flex p-2">Order Summary</h3>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan="2">Item</th>
                                                    <th>Date/Time</th>
                                                    <th>Cost</th>
                                                    <th>Qty</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width="100">
                                                        <img src="/images/full-shot-pro-1.png" alt="hat" width="100" />
                                                    </td>
                                                    <td>
                                                        Lane 2
                                                        <br />
                                                        <span className="text-secondary">Variation ID: 41986</span>
                                                        <br />
                                                        <span className="text-secondary">Range Id: 5580</span>  
                                                    </td>
                                                    <td>15-02-2023, 12:00-13:00</td>
                                                    <td>$10.00</td>
                                                    <td>x2</td>
                                                    <td>$20.00</td>
                                                </tr>

                                                <tr>
                                                    <td width="100">
                                                        <img src="/images/full-shot-pro-1.png" alt="hat" width="100" />
                                                    </td>
                                                    <td>
                                                        Lane 2
                                                        <br />
                                                        <span className="text-secondary">Variation ID: 41986</span>
                                                        <br />
                                                        <span className="text-secondary">Range Id: 5580</span>  
                                                    </td>
                                                    <td>15-02-2023, 12:00-13:00</td>
                                                    <td>$10.00</td>
                                                    <td>x2</td>
                                                    <td>$20.00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default OrderDetail;