import React from 'react';
import { Link } from 'react-router-dom';
import { AddIcon, BackIcon, SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const Products = () => {


    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <BackIcon path={'/categories'} />
                        <AddIcon />
                        <h3>Products</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <input type="search" className="input-dark" placeholder="Search Product" />
                            </div>
                            <div className="col-12">
                                <div className="product-listing">
                                    <div className="card live-product">
                                        <Link to={"/product-detail"}>
                                            <img src="./images/full-shot-pro-1.png" alt="Shot Pro Hat" className='img-fluid' />
                                            <div className="detail">
                                                <h3>The ShotPro Hat</h3>
                                                <div className="qty">
                                                    <p>Quatity <span>23</span></p>
                                                    <p>Price <span className="theme-text"><i>$40</i></span></p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card">
                                        <Link to={"/product-detail"}>
                                            <img src="./images/full-shot-pro-1.png" alt="Shot Pro Hat" className='img-fluid' />
                                            <div className="detail">
                                                <h3>The ShotPro Hat</h3>
                                                <div className="qty">
                                                    <p>Quatity <span>23</span></p>
                                                    <p>Price <span className="theme-text"><i>$40</i></span></p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card">
                                        <Link to={"/product-detail"}>
                                            <img src="./images/full-shot-pro-1.png" alt="Shot Pro Hat" className='img-fluid' />
                                            <div className="detail">
                                                <h3>The ShotPro Hat</h3>
                                                <div className="qty">
                                                    <p>Quatity <span>23</span></p>
                                                    <p>Price <span className="theme-text"><i>$40</i></span></p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card">
                                        <Link to={"/product-detail"}>
                                            <img src="./images/full-shot-pro-1.png" alt="Shot Pro Hat" className='img-fluid' />
                                            <div className="detail">
                                                <h3>The ShotPro Hat</h3>
                                                <div className="qty">
                                                    <p>Quatity <span>23</span></p>
                                                    <p>Price <span className="theme-text"><i>$40</i></span></p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card live-product">
                                        <Link to={"/product-detail"}>
                                            <img src="./images/full-shot-pro-1.png" alt="Shot Pro Hat" className='img-fluid' />
                                            <div className="detail">
                                                <h3>The ShotPro Hat</h3>
                                                <div className="qty">
                                                    <p>Quatity <span>23</span></p>
                                                    <p>Price <span className="theme-text"><i>$40</i></span></p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Products;