import React from "react";
import { Link } from "react-router-dom";
import { SideBarIcon } from "../../../components/TopHeader/TopHeader";
import SidebarMenu from "../../../layouts/SidebarMenu/SidebarMenu";

const OrderListing = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <h3>Orders Management</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Customer Name</th>
                                                <th className="text-center">Date</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Total</th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            <tr>
                                                <td>#42007</td>
                                                <td>Saurabh Kumar</td>
                                                <td className="text-center">Feb 1, 2023</td>
                                                <td align="center"><label class="bg-warning bg-opacity-50 p-1 px-3 rounded">Processing</label></td>
                                                <td className="text-center">$54.99</td>
                                                <td className="theme-text text-center">
                                                    <Link to={"/order-detail"}>View</Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="paggination">
                                    <Link className="btn">+</Link>
                                    <ul>
                                        <li><Link>1</Link></li>
                                        <li><Link className="active">2</Link></li>
                                        <li><Link>3</Link></li>
                                    </ul>
                                    <Link className="btn">-</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default OrderListing;