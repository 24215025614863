import React from 'react';
import { Link } from 'react-router-dom';
import { SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const Membership = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <h3>Membership Management</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="membership">
                                    <div className="outer">
                                        <p className="main-tab-heading-sun theme-text-blue-grey plans">-Midtown Plans-</p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="indvidual">
                                                    <Link to={"/membership-detail"}>
                                                        <h3 className="ind-heading">Indvidual</h3>
                                                        <div className="entry-fee">
                                                            <div className="annual">
                                                                <p className="doller">$480.00</p>
                                                                <p className="year">Annual</p>
                                                            </div>
                                                            <div className="annual pe-3">
                                                                <p className="doller">$39.99</p>
                                                                <p className="year">Monthly</p>
                                                            </div>
                                                        </div>
                                                        <div className="member-icon">
                                                            <img src="./images/icons/members_icon.svg" alt="" />
                                                            <span className="member-text ps-2">3287 members active</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="indvidual">
                                                    <h3 className="ind-heading">Family</h3>
                                                    <div className="entry-fee">
                                                        <div className="annual">
                                                            <p className="doller">$660.00</p>
                                                            <p className="year">Annual</p>
                                                        </div>
                                                        <div className="annual pe-3">
                                                            <p className="doller">$54.99</p>
                                                            <p className="year">Monthly</p>
                                                        </div>
                                                    </div>
                                                    <div className="member-icon">
                                                        <img src="./images/icons/members_icon.svg" alt="" />
                                                        <span className="member-text ps-2">238 members active</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="add-members">
                                                    <img src="./images/icons/add_icon_grey.svg" alt="" />
                                                    <p className="add-member-text"> Create</p>
                                                </div>
                                            </div>
                                            <div className="member-heding">
                                                <p className="ship-title">Add $10/mo. to your monthly membership (or $100/yr. to your annual membership)
                                                    to enjoy shooting events, leagues and SYNC simulator for free. All monthly memberships are
                                                    month-to-month with NO CONTRACTS and can be cancelled anytime with 30 days’ notice. The first
                                                    month includes a non-refundable processing fee payable on enrollment.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="management-add">
                                        <img src="./images/icons/add_icon_grey.svg" alt="" />
                                            <p className="managa-text">Add new plan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Membership;