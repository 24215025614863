import React from 'react';
import { AddIcon, BackIcon, SideBarIcon } from '../../../components/TopHeader/TopHeader';
import SidebarMenu from '../../../layouts/SidebarMenu/SidebarMenu';

const BoundBook = () => {
    return (
        <>
            <div className="wrapper d-flex">
                <SidebarMenu />
                <div className="wrapper-body">
                    <div className="top-header pe-4">
                        <SideBarIcon />
                        <BackIcon path={'/firearms'} />
                        <AddIcon />
                        <h3>Manage BoundBook</h3>
                    </div>
                    <div className="container-fluid p-4">
                        <div className="row">
                            <div className="col-12">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th width="400">Description</th>
                                            <th className='text-center'>Tag</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Shot Gun</td>
                                            <td>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iste iusto harum nam eius maiores! Sed aut quisquam ullam nulla consequuntur.</td>
                                            <td align='center'><span className='badge bg-secondary bg-opacity-2 rounded py-1 px-2 '>Acquisition</span></td>
                                            <td>Sep 23, 2022</td>
                                        </tr>
                                        <tr>
                                            <td>Riffle</td>
                                            <td>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</td>
                                            <td align='center'><span className='badge bg-secondary bg-opacity-2 rounded py-1 px-2 '>Disposition</span></td>
                                            <td>Sep 23, 2022</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BoundBook;